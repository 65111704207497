import React, {memo, useCallback} from 'react';

import Button from './Button';
import ButtonGroup from './ButtonGroup';
import config from '../config';
import dataStream from '../streams/dataStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import I18n from '../libs/I18n';
import Icon from './Icon';
import languageStream from '../streams/languageStream';
import link from '../utils/link';
import openStepActionStream from '../streams/openStepActionStream';
import orderStream from '../streams/orderStream';
import selectColorActionStream from '../streams/selectColorActionStream';
import styles from './ColorStep.css';
import {Color} from '../types/data';
import {useStream} from '../hooks/useStream';

const VAT_MODIFIER = config.VAT_MODIFIER; // eslint-disable-line prefer-destructuring

let i18n = new I18n();

export interface ColorDetailProps {
	color: Color;
	isSelected: boolean;
	handleClick: (color: Color) => void;
}

export let ColorDetail = memo(({
	color,
	isSelected,
	handleClick
}: ColorDetailProps) => {
	useStream(languageStream);
	
	let onClick = useCallback(
		(event) => {
			if (handleClick) {
				handleClick(color);
			}
		},
		[color, handleClick],
	);
	
	let order = useStream(orderStream);
	let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
	
	// let {windowPrice: currentWindowPrice} = getOrderItemPrice(orderItem);
	// let {windowPrice: nextWindowPrice} = getOrderItemPrice(Object.assign({}, orderItem, {
	//   color: {
	//     id: color.id,
	//     name: color.name,
	//     category: color.category,
	//     sidesCount: sidesCount,
	//     priceModifiers: color.priceModifiers,
	//   }
	// }));
	let delta = 0; // getConvertedPrice((nextWindowPrice - currentWindowPrice) * VAT_MODIFIER[languageStream.value.current]);
	
	return (
		<div className={styles.colorDetail + (isSelected ? ` ${styles.isSelected}` : '')} onClick={onClick}>
			<figure className={styles.colorDetailPreview} style={{backgroundColor: color.rgbHex}}>
				{color.thumb ? <img src={link('assets', color.thumb)} alt={color.name[languageStream.value.current]} /> : null}
			</figure>
			<div className={styles.colorDetailOverlay}>
				<p className={styles.colorDetailOverlayHeading}>
					{i18n.getTranslationString(color.name[languageStream.value.current])}
				</p>
				<p className={styles.colorDetailOverlayPriceComparison}>
					{delta >= 0
						? i18n.translate`ColorStep - color detail - positive price - ${delta}:c`
						: i18n.translate`ColorStep - color detail - negative price - ${delta * -1}:c`}
				</p>
				{color.image ? 
					<figure className={styles.colorDetailOverlayPhoto}>
						<img src={link('assets', color.image)} alt={color.name[languageStream.value.current]} />
      				</figure> : 
					null}
			</div>
		</div>
	);
});

export interface ColorStepProps {
	isOpen: boolean;
}

export let ColorStep = memo(({isOpen}: ColorStepProps) => {
	useStream(languageStream);
	
	let {colors} = useStream(dataStream);
	
	let handleCloseClick = useCallback(() => {
		openStepActionStream.push(null);
	}, []);
	
	let handleSelectColor = useCallback((color) => {
		selectColorActionStream.push({
			action: color.type === 'PROFILE' ? 'SET_PROFILE_COLOR' : 'SET_LAMELLA_COLOR',
			payload: color,
		});
	}, []);
	
	let order = useStream(orderStream);
	let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
	
	if (!orderItem) {
		return null;
	}
	
	let baseUrl = 'okna-hned.cz';
	switch (languageStream.value.current) {
		case 'sk-SK':
			baseUrl = 'okna-hned.sk';
			break;
		case 'de-DE':
			baseUrl = 'fenster-jetzt.de';
			break;
		case 'de-AT':
			baseUrl = 'fenster-jetzt.at';
			break;
		case 'hu-HU':
			baseUrl = 'ablakok-most.hu';
			break;
	}
	
	return (
		<section className={styles.root + (isOpen ? ` ${styles.isOpen}` : '')}>
			<div className={styles.wrapper}>
				<h1 className={styles.heading}>{i18n.translate`ColorStep - heading 1`}</h1>
				<span className={styles.closeButton}>
					<Button type="invisible" size="large" icon="cross" handleClick={handleCloseClick} />
        		</span>
				
				<div>
					<div className={styles.panels}>
						<div className={styles.colorCategory}>
							<h5 className={styles.colorCategoryHeading}>{i18n.translate`StepsList - color - profile`}</h5>
							<ul className={styles.colorsList}>
								{colors
								.filter((color) => color.type === 'PROFILE')
								.map((color, index) => (
									<li key={index}>
										<ColorDetail
											color={color}
											isSelected={orderItem?.profileColor?.id === color.id}
											handleClick={handleSelectColor}
										/>
									</li>
								))}
							</ul>
						</div>
						
						<div className={styles.colorCategory}>
							<h5 className={styles.colorCategoryHeading}>{i18n.translate`StepsList - color - lamella`}</h5>
							<ul className={styles.colorsList}>
								{colors
								.filter((color) => color.type === 'LAMELLA')
								.map((color, index) => (
									<li key={index}>
										<ColorDetail
											color={color}
											isSelected={orderItem?.lamellaColor?.id === color.id}
											handleClick={handleSelectColor}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					
					<div className={styles.needHelpLink}>
						<a href={`https://www.${baseUrl}/content/58-barvy-zaluzii`} target="_blank">
							{i18n.translate`ColorStep - color swatch`}
						</a>
					</div>
					
					<ButtonGroup align="center">
						<Button
							type="flat"
							size="large"
							label={i18n.translate`ColorStep - button 1`}
							isDisabled={!(orderItem && orderItem.material)}
							handleClick={handleCloseClick}
						/>
					</ButtonGroup>
				</div>
			</div>
		</section>
	);
});
