/* eslint-disable quote-props */

import constants from '../../internals/constants';

const {CS_CZ} = constants;
const {SK_SK} = constants;
const {DE_DE} = constants;
const {DE_AT} = constants;
const {HU_HU} = constants;

let i18nStrings: any = {
	/* Header */
	'Header - logo - claim': {
		[CS_CZ]: 'Kalkulátor – vyberte si žaluzie přesně podle svých představ!',
		[SK_SK]: 'Kalkulátor - vyberte si žalúzie presne podľa svojich predstáv!',
		[DE_DE]: 'Fensterkonfigurator - wählen Sie die Jalousien genau nach Ihren Vorstellungen!',
		[HU_HU]: 'Kalkulátor - szerkessze meg reluxáját pontosan az elképzelései szerint!'
	},
	'Header - window - configuring': {
		[CS_CZ]: 'nyní kalkulujete:',
		[SK_SK]: 'teraz kalkulujete:',
		[DE_DE]: 'jetzt konfigurieren:',
		[HU_HU]: 'szerkesztés:'
	},
	'Header - window - {0}': {
		[CS_CZ]: 'Žaluzie #{0}',
		[SK_SK]: 'Žalúzie #{0}',
		[DE_DE]: 'Jalousie',
		[HU_HU]: 'Reluxa #{0}'
	},
	'Header - price - actual': {
		[CS_CZ]: 'Cena:',
		[SK_SK]: 'Cena',
		[DE_DE]: 'Preis',
		[HU_HU]: 'Ár'
	},
	'Header - price - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'Header - price - no VAT': {
		[CS_CZ]: 'cena bez DPH:',
		[SK_SK]: 'cena bez DPH:',
		[DE_DE]: 'Preis ohne Mwst.',
		[HU_HU]: 'nettó - ÁFA nélkül'
	},
	'Header - price - VAT': {
		[CS_CZ]: 'cena včetně DPH:',
		[SK_SK]: 'cena vrátane DPH:',
		[DE_DE]: 'Preis MwSt. Inklusive',
		[HU_HU]: 'bruttó - 27%-os ÁFÁ-val'
	},
	'Header - videotutorial': {
		[CS_CZ]: 'Návod – zaměření a montáž',
		[SK_SK]: 'Návod - zameranie a montáž',
		[DE_DE]: 'Anleitung - Ausrichtung und Installation',
		[HU_HU]: 'A méretek meghatározásának és a reluxa felszerelésének útmutatója'
	},
	'html - head - title': {
		[CS_CZ]: 'Kalkulátor žaluzií – žaluzie na míru | OKNA HNED',
		[SK_SK]: 'Kalkulátor žalúzií - žalúzie na mieru | OKNÁ HNEĎ',
		[DE_DE]: 'Jalousierechner - benutzerdefinierte Jalousien FENSTER JETZT',
		[HU_HU]: 'Reluxa árkalkulátor - méretre gyártott reluxák'
	},
	'html - head - meta description': {
		[CS_CZ]: 'Vytvořte si vlastní žaluzie na míru v jednoduchém kalkulátoru. Vaše vlastní žaluzie můžete mít na pár kliknutí.',
		[SK_SK]: 'Vytvorte si vlastné žalúzie na mieru v jednoduchom kalkulátora. Vaše vlastné žalúzie môžete mať na pár kliknutí.',
		[DE_DE]: 'Erstellen Sie Ihre eigenen Jalousien in einem einfachen Taschenrechner. Mit wenigen Klicks können Sie Ihre eigenen Blinds haben.',
		[HU_HU]: 'Szerkessze meg reluxáit az átlátható kalkulátorunkban. Az árajánlatot pár kattintás után megtekintheti.'
	},
	
	/* Footer */
	'Footer - links - about us': {
		[CS_CZ]: 'O nás',
		[SK_SK]: 'O nás',
		[DE_DE]: 'Über uns',
		[HU_HU]: 'Rólunk'
	},
	'Footer - links - terms': {
		[CS_CZ]: 'Obchodní podmínky',
		[SK_SK]: 'Obchodné podmienky',
		[DE_DE]: 'Handelsbedingungen',
		[HU_HU]: 'Általános szerződési feltételek'
	},
	'Footer - links - delivery': {
		[CS_CZ]: 'Doba dodání',
		[SK_SK]: 'Doba dodania',
		[DE_DE]: 'Lieferzeit',
		[HU_HU]: 'Gyártási idő'
	},
	'Footer - links - stored': {
		[CS_CZ]: 'Okna skladem',
		[SK_SK]: 'Okná skladom',
		[DE_DE]: 'Lagerfenster',
		[HU_HU]: 'Raktáról elérhető ablakok'
	},
	
	/* Main */
	'Main - intro - heading': {
		[CS_CZ]: 'Kalkulátor žaluzií',
		[SK_SK]: 'Kalkulátor žalúzií',
		[DE_DE]: 'Jalousienkonfigurator',
		[HU_HU]: 'Reluxa árkalkulátor'
	},
	'Main - intro - button start': {
		[CS_CZ]: 'Začít s kalkulací',
		[SK_SK]: 'Začať s kalkuláciou',
		[DE_DE]: 'Jetzt konfigurieren',
		[HU_HU]: 'Szerkesztés'
	},
	'Main - intro - button videotutorial': {
		[CS_CZ]: 'Spustit videonávod',
		[SK_SK]: 'Spustiť videonávod',
		[DE_DE]: 'Videoanleitung starten',
		[HU_HU]: 'Videós útmutató'
	},
	'Main - intro - text 1': {
		[CS_CZ]: 'Nabízíme přehledný online kalkulátor žaluzií, pomocí kterého si sami vyberete žaluzie, které se vám hodí!',
		[SK_SK]: 'Ponúkame prehľadný online kalkulátor žalúzií, pomocou ktorého si sami vyberiete žalúzie, ktoré sa vám hodia!',
		[DE_DE]: 'Wir bieten einen übersichtlichen Online-Jalousienkonfigurator, mit dem Sie die für Sie passenden Jalousien auswählen können!',
		[HU_HU]: 'Átlátható online kalkulátorunk segítségével megszerkesztheti az Önnek tetsző reluxát!'
	},
	'Main - intro - claim 1': {
		[CS_CZ]: 'Rychlé dodání',
		[SK_SK]: 'Rýchle dodanie',
		[DE_DE]: 'Schnelle Lieferung',
		[HU_HU]: 'Gyors kiszállítás '
	},
	'Main - intro - claim 2': {
		[CS_CZ]: 'Bezpečný e-shop',
		[SK_SK]: 'Bezpečný e- shop',
		[DE_DE]: 'Sicherer Onlineshop',
		[HU_HU]: 'Biztonságos webshop'
	},
	'Main - intro - claim 3': {
		[CS_CZ]: 'Bezpečné balení zásilek',
		[SK_SK]: 'Bezpečné balenie zásielok',
		[DE_DE]: 'Sichere Verpackung von Sendungen',
		[HU_HU]: 'Gondos csomagolás'
	},
	'Main - intro - claim 4': {
		[CS_CZ]: 'Jednoduché objednání',
		[SK_SK]: 'Jednoduché objednanie',
		[DE_DE]: 'Einfache Bestellung',
		[HU_HU]: 'Egyszerű megrendelés'
	},
	
	/* StepsList  */
	'StepsList - window size': {
		[CS_CZ]: 'Výrobní rozměr žaluzií',
		[SK_SK]: 'Výrobný rozmer žalúzií',
		[DE_DE]: 'Produktionsgröße von Jalousien',
		[HU_HU]: 'A reluxa gyártási méretei'
	},
	'StepsList - width': {
		[CS_CZ]: 'Šířka',
		[SK_SK]: 'Šírka',
		[DE_DE]: 'Breite',
		[HU_HU]: 'Szélesség'
	},
	
	'StepsList - height': {
		[CS_CZ]: 'Výška',
		[SK_SK]: 'Výška',
		[DE_DE]: 'Höhe',
		[HU_HU]: 'Magasság'
	},
	'StepsList - max width': {
		[CS_CZ]: 'Maximální velikost, kterou umíme vyrobit, je 2500 x 2500 mm.',
		[SK_SK]: 'Maximálna veľkosť, ktorú vieme vyrobiť, je 2500 x 2500 mm.',
		[DE_DE]: 'Die maximale Größe, die wir produzieren können, beträgt 2500 x 2500 mm.',
		[HU_HU]: 'Az általunk legyártható maximális méret 2500 x 2500 mm.'
	},
	'StepsList - max height': {
		[CS_CZ]: 'Minimální velikost, kterou umíme vyrobit, je 300 x 300 mm.',
		[SK_SK]: 'Minimálna veľkosť, ktorú vieme vyrobiť, je 300 x 300 mm.',
		[DE_DE]: 'Die Mindestgröße, die wir produzieren können, beträgt 300 x 300 mm.',
		[HU_HU]: 'Az általunk legyártható minimális méret 300 x 300 mm.'
	},
	'StepsList - how to measure': {
		[CS_CZ]: 'Jak měřit?',
		[SK_SK]: 'Ako merať?',
		[DE_DE]: 'Wie zu messen?',
		[HU_HU]: 'A méretek meghatározása'
	},
	'StepsList - color': {
		[CS_CZ]: 'Barva žaluzie',
		[SK_SK]: 'Farba žalúzie',
		[DE_DE]: 'Jalousie Farbe',
		[HU_HU]: 'A reluxa színe'
	},
	'StepsList - color - profile': {
		[CS_CZ]: 'Barva profilu',
		[SK_SK]: 'Farba profilu',
		[DE_DE]: 'Profilfarbe',
		[HU_HU]: 'A profil színe'
	},
	'StepsList - color - lamella': {
		[CS_CZ]: 'Barva lamely',
		[SK_SK]: 'Farba lamely',
		[DE_DE]: 'Lamellenfarbe',
		[HU_HU]: 'A lamellák színe'
	},
	'StepsList - change color': {
		[CS_CZ]: 'Změnit barvy',
		[SK_SK]: 'Zmeniť farby',
		[DE_DE]: 'Ändern Sie die Farben',
		[HU_HU]: 'A szín megváltoztatása'
	},
	'StepsList - windows specification': {
		[CS_CZ]: 'Specifikace okna',
		[SK_SK]: 'Špecifikácie okna',
		[DE_DE]: 'Fensterspezifikationen',
		[HU_HU]: 'Az ablak jellemzői'
	},
	'StepsList - control': {
		[CS_CZ]: 'Ovládání žaluzií',
		[SK_SK]: 'Ovládanie žalúzií',
		[DE_DE]: 'Jalousiesteuerung',
		[HU_HU]: 'A vezérlőlánc oldala'
	},
	'StepsList - glass': {
		[CS_CZ]: 'Sklo okna',
		[SK_SK]: 'Sklo okna',
		[DE_DE]: 'Fensterglas',
		[HU_HU]: 'Az ablak üvegezése'
	},
	'StepsList - material': {
		[CS_CZ]: 'Materiál okna',
		[SK_SK]: 'Materiál okna',
		[DE_DE]: 'Fenstermaterial',
		[HU_HU]: 'Az ablak anyaga'
	},
	'StepsList - windows specification why': {
		[CS_CZ]: 'Proč to potřebujeme vědět?',
		[SK_SK]: 'Prečo to potrebujeme vedieť?',
		[DE_DE]: 'Warum müssen wir wissen?',
		[HU_HU]: 'Miért van szükségünk erre az adatra?'
	},
	'StepsList - my sunblinds': {
		[CS_CZ]: 'Moje žaluzie',
		[SK_SK]: 'Moje žalúzie',
		[DE_DE]: 'Meine Jalousien',
		[HU_HU]: 'Reluxáim'
	},
	'StepsList - add sunblinds': {
		[CS_CZ]: 'Přidat další žaluzie',
		[SK_SK]: 'Pridať ďalšie žalúzie',
		[DE_DE]: 'Fügen Sie weitere Jalousien hinzu',
		[HU_HU]: 'Új reluxa hozzáadása'
	},
	'StepsList - add sunblinds overlay': {
		[CS_CZ]: 'Přidejte do objednávky žaluzii pro další okno s jinými parametry',
		[SK_SK]: 'Pridajte do objednávky žalúziu pre ďalšie okno s inými parametrami',
		[DE_DE]: 'Fügen Sie der Bestellung für ein anderes Fenster mit anderen Parametern eine Jalousien hinzu',
		[HU_HU]: 'Más jellemzőkkel rendelkező reluxa hozzáadása'
	},
	'StepsList - order': {
		[CS_CZ]: 'Objednávka',
		[SK_SK]: 'Objednávka',
		[DE_DE]: 'Bestellung',
		[HU_HU]: 'Megrendelés'
	},
	'StepsList - order - button': {
		[CS_CZ]: 'Přehled objednávky',
		[SK_SK]: 'Prehľad objednávky',
		[DE_DE]: 'Bestellungsübersicht',
		[HU_HU]: 'Összegzés'
	},
	'StepsList - order - info': {
		[CS_CZ]: 'Pokud je vaše kalkulace dokončena, můžete přejít ke kontrole a vytvořit objednávku.',
		[SK_SK]: 'Pokiaľ je vaša kalkulácia dokončená, môžete prejsť ku kontrole a vytvoriť objednávku.',
		[DE_DE]: 'Falls Ihre Bestellung gefertig ist, gehen Sie zur Bestellungsübersicht und platzieren Sie die Bestellung.',
		[HU_HU]: 'Amennyiben befejezte a szerkesztést, tekintse meg az összegzést és hozza létre a megrendelést.'
	},
	
	/* How to measure */
	'How to measure - text 1': {
		[CS_CZ]: 'POZOR do kalkulátoru se zadávají VÝROBNÍ ROZMĚRY ŽALUZIÍ v milimetrech. Ty získáte z naměřených hodnot tak, že OD ŠÍŘKY ODEČTETE 2 mm a K VÝŠCE PŘIČTETE 10 mm!!!',
		[SK_SK]: 'POZOR do kalkulátora sa zadávajú VÝROBNÉ ROZMERY ŽALÚZIOU v milimetroch.Tie získate z nameraných hodnôt tak, že OD ŠÍRKY odčítame 2 mm a K VÝŠKE pripočítajte 10 mm!!!',
		[DE_DE]: 'ACHTUNG Die PRODUKTIONSDIMENSIONEN DIE JALOUSIEN in Millimetern werden in den Fensterkonfigurator eingegeben. Berechnen Sie die Produktionsabmessungen, indem Sie 2 mm von der Breite abziehen und 10 mm zur Höhe hinzufügen !!!',
		[HU_HU]: 'FIGYELEM: a kalkulátorba a RELUXA GYÁRTÁSI MÉRETEIT milliméterekben adja meg. Ezen méreteket a lemért értékekből kapja meg úgy, hogy a SZÉLESSÉGBŐL KIVON 2 mm - t a MAGASSÁGHOZ PEDIG HOZZÁAD 10 mm - t!!!',
	},
	'How to measure - text 2': {
		[CS_CZ]: 'Výrobní rozměry žaluzií jsou skutečné rozměry hotového výrobku. U šířky zadaný rozměr odpovídá šířce hliníkové lamely žaluzie. Ze zkušenosti doporučujeme od naměřeného rozměru odečíst 2 milimetry, aby byla jistota, že žaluzie nebudou při spouštění a vytahování zadrhávat o zasklívací lišty oken. Naopak pro lepší zastínění a dosednutí žaluzií až na spodní zasklívací lištu doporučujeme k naměřené výšce přičíst 10 milimetrů.',
		[SK_SK]: 'Výrobné rozmery žalúzií sú skutočné rozmery hotového výrobku. U šírky zadaný rozmer zodpovedá šírke hliníkové lamely žalúzie. Zo skúsenosti doporučujeme od nameraného rozmeru odpočítať 2 milimetre, aby bola istota, že žalúzie nebudú pri spúšťaní a vyťahovaní zadrhávať o zasklievacej lišty okien. Naopak pre lepšiu zatienenie a dosadnutie žalúzií až na spodnú zasklievaciu lištu odporúčame k nameranej výške pripočítať 10 milimetrov.',
		[DE_DE]: 'Die Produktionsabmessungen von Jalousien sind die tatsächlichen Abmessungen des fertigen Produkts. Die für die Breite eingegebene Breite entspricht der Breite des Aluminiumlamelle. Wir empfehlen, 2 Millimeter von der gemessenen Abmessung abzuziehen und 10 Millimeter zur gemessenen Höhe hinzuzufügen.',
		[HU_HU]: 'A reluxa gyártási méretei a legyártott termék valós méretei. A megadott szélesség megegyezik a reluxa alumínium lamelláinak szélességével. Tapasztalataink alapján ajánljuk a 2 miliméter kivonását a mért szélességből, így biztosan nem fogják súrolni a lamellák az üvegezőlécet fel- és lehúzáskor.'
	},
	'How to measure - text 3': {
		[CS_CZ]: 'OVLÁDÁNÍ ŽALUIZIÍ (řetízek) se umisťuje NA TU STRANU OKNA, KDE JSOU PANTY (při pohledu zevnitř). Tedy na opačnou stranu, než kde je klika, která by ovládání zavazela.',
		[SK_SK]: 'OVLÁDANIE ŽALUIZIÍ(retiazka) sa umiestňuje NA TU STRANU OKNA, KDE SÚ PÁNTY(pri pohľade zvnútra).Teda na opačnú stranu, než kde je kľučka, ktorá by ovládanie zavadzala.',
		[DE_DE]: 'Bedienung der Jalousien (Kette) befindet sich an der Seite des Fensters, wo sich die Scharniere befinden (von innen gesehen). Auf der Seite, auf der sich der Griff befindet, wäre die Bedienung der Jalousien bindend.',
		[HU_HU]: 'A RELUXA VEZÉRLŐLÁNCA: a gyöngylánc elhelyezése a PÁNTOK OLDALÁN ajánlott (belső nézetből), vagyis a kiliccsel ellentétes oldalon, mivel a kilincs megnehezíthetné a reluxa kezelését.'
	},
	'How to measure - text 4': {
		[CS_CZ]: 'Zaměření žaluzií je velmi jednoduché!',
		[SK_SK]: 'Zameranie žalúzií je veľmi jednoduché!',
		[DE_DE]: 'Die Jalousien vermessen ist sehr einfach!',
		[HU_HU]: 'A reluxa méreteinek meghatározása rendkívül egyszerű!'
	},
	'How to measure - text 5': {
		[CS_CZ]: 'Měřte těsně u skla od jedné zasklívací lišty ke druhé (včetně gumového či silikonového těsnění). Snažte se o co nejpřesnější zaměření!',
		[SK_SK]: 'Merajte tesne pri skle od jednej zasklievacej lišty k druhej(vrátane gumového či silikónového tesnenia).Snažte sa o čo najpresnejšie zameranie!',
		[DE_DE]: 'Messen Sie in der Nähe des Glases von einer Glasleiste zur nächsten (einschließlich Gummi- oder Silikondichtungen). Versuche so genau wie möglich zu fokussieren!',
		[HU_HU]: 'Szorosan az üveg mentén, az egyik üvegezőléctől a másikig mérjen (beleértve a gumi vagy szilikon tömítést is). Igyekezzen a lehető legpontosabban mérni!'
	},
	'How to measure - text 6': {
		[CS_CZ]: 'Měření proveďte 3x a to na krajích a uprostřed každé strany. Vyberte vždy ten nejmenší naměřený rozměr ze tří hodnot šířky i výšky.',
		[SK_SK]: 'Meranie urobte 3x a to na krajoch a uprostred každej strany.Vyberte vždy ten najmenší nameraný rozmer z troch hodnôt šírky aj výšky.',
		[DE_DE]: 'Messen Sie dreimal an den Rändern und in der Mitte jeder Seite. Wählen Sie immer die kleinste gemessene Abmessung aus den drei Werten für Breite und Höhe aus.',
		[HU_HU]: 'A mérést ismételje meg háromszor, mégpedig a felső, közép és alsó részben. Válassza ki a legkisebb lemért szélességet és magasságot.'
	},
	'How to measure - text 7': {
		[CS_CZ]: 'Dejte pozor, ať šířku a výšku nezaměníte!',
		[SK_SK]: 'Dajte pozor, nech šírku a výšku nezameníte!',
		[DE_DE]: 'Achten Sie darauf, Breite und Höhe nicht zu verwechseln!',
		[HU_HU]: 'Ügyeljen arra, hogy a szélességet és magasságot ne keverje össze!'
	},
	'How to measure - text 8': {
		[CS_CZ]: 'Tyto rozměry ještě před zadáním do kalkulátoru upravte dle doporučení uvedeného výše.',
		[SK_SK]: 'Tieto rozmery ešte pred zadaním do kalkulátora upravte podľa odporúčaní uvedeného vyššie.',
		[DE_DE]: 'Passen Sie diese Abmessungen gemäß den obigen Empfehlungen an, bevor Sie sie in den Fensterkonfigurator eingeben.',
		[HU_HU]: 'A lemért értékeket a kalkulátorba való beadás előtt a fentiek alapján korrigálja.'
	},
	'How to measure - text 9': {
		[CS_CZ]: 'U trojskla Vám dodáme zcela zdarma potřebné distanční podložky, takže hloubku zasklívací lišty měřit nemusíte.',
		[SK_SK]: 'U trojskla Vám dodáme úplne zadarmo potrebné dištančné podložky, takže hĺbku zasklievacej lišty merať nemusíte.',
		[DE_DE]: 'Für die Dreifachverglasung stellen wir Ihnen kostenlos die erforderlichen Abstandshalter zur Verfügung, damit Sie die Tiefe der Verglasungsleiste nicht messen müssen.',
		[HU_HU]: 'A háromrétegű üvegezéssel ellátott ablakok esetében ingyenesen biztosítunk távtartó alátéteket, így az üvegező léc mélységét nem szükséges lemérni.'
	},
	
	/* windows specification why */
	'Windows specification why - text 1': {
		[CS_CZ]: 'Výběrem pravá či levá si sami určíte, na které straně bude vyveden ovládací řetízek. Zpravidla se umisťuje na stranu, kde jsou panty, aby nepřekážel při manipulaci s klikou.',
		[SK_SK]: 'Výberom pravá či ľavá si sami určíte, na ktorej strane bude vyvedený ovládaciu retiazku.Spravidla sa umiestňuje na stranu, kde sú pánty, aby neprekážal pri manipulácii s kľučkou.',
		[DE_DE]: 'Durch Auswahl von rechts oder links können Sie bestimmen, auf welcher Seite die Steuerkette geführt wird. Es wird normalerweise an der Seite platziert, an der sich die Scharniere befinden, um die Handhabung des Griffs nicht zu beeinträchtigen.',
		[HU_HU]: 'Meghatározhatja a vezérlőlánc oldalát, azaz hogy a bal vagy a jobb oldalon helyezkedjen-e el a gyöngylánc. Általában a pántok oldalát ajánlott megjelölni, így a lánc nem akadályozza a kilincs használatát.'
	},
	'Windows specification why - text 2': {
		[CS_CZ]: 'Řetízkem je možné žaluzie jak naklápět, tak i posouvat nahoru dolů.',
		[SK_SK]: 'Retiazkou je možné žalúzie ako naklápať, tak i posúvať hore dole.',
		[DE_DE]: 'Die Kette kann sowohl gekippt als auch auf und ab bewegt werden.',
		[HU_HU]: 'A gyöngylánc segítségével a reluxa lamellái dönthetők, illetve fel és le mozgathatók.'
	},
	'Windows specification why - text 3': {
		[CS_CZ]: 'Pokud je v okně či dveřích instalováno trojsklo, dodáme vám zdarma distanční podložky, které použijete pod horní nosníky (lištu). Tyto podložky zajistí, že se celé ovládání žaluzií na horní liště vejde do užšího prostoru, který použitím trojskla obvykle vzniká.',
		[SK_SK]: 'Pokiaľ je v okne či dverách inštalované trojsklo, dodáme vám zdarma dištančné podložky, ktoré použijete pod hornú nosníky(lištu).Tieto podložky zaistí, že sa celé ovládanie žalúzií na hornej lište vojde do užšieho priestoru, ktorý použitím trojskla obvykle vzniká.',
		[DE_DE]: 'Wenn im Fenster oder in der Tür eine Dreifachverglasung installiert ist, stellen wir Ihnen kostenlose Abstand Unterlage zur Verfügung, die Sie unter der oberen Leiste verwenden. Diese Unterlage stellen sicher, dass die gesamte Steuerung der Jalousien an der oberen Leiste in den engeren Raum passt, der normalerweise durch die Verwendung von Dreifachverglasung entsteht.',
		[HU_HU]: 'Amennyiben az ajtó vagy ablak háromrétegű üvegezéssel rendelkezik, úgy ingyenesen biztosítjuk a távtartó alátéteket, melyekett a felső tartókonzol alá kell helyezni. Ezek az alátétek biztosítják, hogy a reluxa teljes egészében befér a szűkebb helyre, ami a háromrétegű üvegezés miatt keletkezik.'
	},
	'Windows specification why - text 4': {
		[CS_CZ]: 'Pokud objednáváte žaluzie do EURO oken či dveří, bude váš balíček obsahovat i speciální šrouby („červíky“) určené pro zavrtání do spodní zasklívací lišty. Ty pak slouží pro ukotvení plastových fixačních klínků vodicích silonů.',
		[SK_SK]: 'Pokiaľ objednávate žalúzie do EURO okien či dverí, bude váš balíček obsahovať aj špeciálne skrutky( "červíkov") určené pre zavŕtanie do spodnej zasklievacej lišty.Tie potom slúžia pre ukotvenie plastových fixačných klínkov vodiacich silónov.',
		[DE_DE]: 'Wenn Sie Jalousien für EURO-Fenster oder -Türen bestellen, enthält Ihr Paket auch spezielle Schrauben ("Würmer") zum Bohren in die untere Verglasungsleiste. Diese dienen dann zur Verankerung der Kunststoffbefestigungskeile der Führungsnylons.',
		[HU_HU]: 'Amennyiben a reluxát FA ablakra vagy ajtóra rendeli, úgy a csomag speciális rögzítő csavarokat is tartalmaz, melyeket az alsó üvegező lécbe kell fúrni. Ezen csavarok a vezér szilon végén található rögzítő ékek üvegezőléchez való odaerősítését szolgálják. '
	},
	
	/* Color step */
	'ColorStep - heading 1': {
		[CS_CZ]: 'Barva profilu a lamely',
		[SK_SK]: 'Farba profilu a lamely',
		[DE_DE]: 'Profil- und Lamellenfarbe',
		[HU_HU]: 'A profil és a lamellák színe'
	},
	'ColorStep - button 1': {
		[CS_CZ]: 'Potvrdit výběr a pokračovat',
		[SK_SK]: 'Potvrdiť výber a pokračovať',
		[DE_DE]: 'Auswahl bestätigen und weiterleiten',
		[HU_HU]: 'Megerősítés'
	},
	'ColorStep - color detail - positive price - {0}': {
		[CS_CZ]: '+{0}',
		[SK_SK]: '+{0}',
		[DE_DE]: '+{0}',
		[HU_HU]: '+{0}'
	},
	'ColorStep - color detail - negative price - {0}': {
		[CS_CZ]: '−{0}',
		[SK_SK]: '−{0}',
		[DE_DE]: '−{0}',
		[HU_HU]: '−{0}'
	},
	'ColorStep - color swatch': {
		[CS_CZ]: 'Vzorník barev a dekorů (velké fotografie)',
		[SK_SK]: 'Vzorky farieb a dekorov (veľké fotografie)',
		[DE_DE]: 'Farb- und Dekormuster (große Fotos)',
		[HU_HU]: 'Szín- és dekorációs minták (nagyméretű fotók)'
	},
	
	/* OrderStep */
	'OrderStep - order item link - heading - {0}': {
		[CS_CZ]: 'Žaluzie #{0}',
		[SK_SK]: 'Žalúzie #{0}',
		[DE_DE]: 'Jalousie #{0}',
		[HU_HU]: 'Reluxa #{0}'
	},
	'OrderStep - order item link - button 1': {
		[CS_CZ]: 'Přidat',
		[SK_SK]: 'Pridať',
		[DE_DE]: 'Hinzufügen',
		[HU_HU]: 'Hozzáadás'
	},
	'OrderStep - order item link - button 2': {
		[CS_CZ]: 'Odebrat',
		[SK_SK]: 'Odobrať',
		[DE_DE]: 'Entfernen',
		[HU_HU]: 'Elvetés'
	},
	'OrderStep - order item link - button 3': {
		[CS_CZ]: 'Smazat',
		[SK_SK]: 'Zmazať',
		[DE_DE]: 'Löschen',
		[HU_HU]: 'Törlés'
	},
	'OrderStep - order item link - button 4': {
		[CS_CZ]: 'Přejít na tyto žaluzie',
		[SK_SK]: 'Prejsť na tieto žalúzie',
		[DE_DE]: 'Gehen Sie für diese Jalousien',
		[HU_HU]: 'A reluxa szerkesztése'
	},
	'OrderStep - order item detail - property label 1': {
		[CS_CZ]: 'Cena za kus bez DPH',
		[SK_SK]: 'Cena za kus bez DPH',
		[DE_DE]: 'Preis pro Stück ohne MwSt.',
		[HU_HU]: 'Nettó darabár - ÁFA nélkül'
	},
	'OrderStep - order item detail - property label 2': {
		[CS_CZ]: 'Rozměry',
		[SK_SK]: 'Rozmery',
		[DE_DE]: 'Maße',
		[HU_HU]: 'Méretek'
	},
	'OrderStep - order item detail - property label 3': {
		[CS_CZ]: 'Barva profilu',
		[SK_SK]: 'Farba profilu',
		[DE_DE]: 'Profilfarbe',
		[HU_HU]: 'A profil színe'
	},
	'OrderStep - order item detail - property label 4': {
		[CS_CZ]: 'Barva lamely',
		[SK_SK]: 'Farba lamely',
		[DE_DE]: 'Lamellenfarbe',
		[HU_HU]: 'A lamella színe'
	},
	'OrderStep - order item detail - property label 5': {
		[CS_CZ]: 'Otevírání',
		[SK_SK]: 'Otváranie',
		[DE_DE]: 'Öffnung',
		[HU_HU]: 'A vezérlőlánc oldala'
	},
	'OrderStep - order item detail - property label 6': {
		[CS_CZ]: 'Sklo',
		[SK_SK]: 'Sklo',
		[DE_DE]: 'Glas',
		[HU_HU]: 'Üveg'
	},
	'OrderStep - order item detail - property label 7': {
		[CS_CZ]: 'Materiál',
		[SK_SK]: 'Materiál',
		[DE_DE]: 'Material',
		[HU_HU]: 'Anyag'
	},
	'OrderStep - order item detail - property label 8': {
		[CS_CZ]: 'Počet',
		[SK_SK]: 'Počet',
		[DE_DE]: 'Anzahl',
		[HU_HU]: 'Darabszám'
	},
	'OrderStep - order item detail - property label 9': {
		[CS_CZ]: 'Cena celkem bez DPH',
		[SK_SK]: 'Cena celkom bez DPH',
		[DE_DE]: 'Gesamtpreis ohne MwSt. ',
		[HU_HU]: 'Bruttó ár - 27%-os AFÁval '
	},
	'OrderStep - order item detail - property label 10': {
		[CS_CZ]: 'Žaluzie',
		[SK_SK]: 'Žalúzie',
		[DE_DE]: 'Jalousie',
		[HU_HU]: 'Reluxa'
	},
	'OrderStep - order item detail - property value - {0}': {
		[CS_CZ]: '{0}',
		[SK_SK]: '{0}',
		[DE_DE]: '{0}',
		[HU_HU]: '{0}'
	},
	'OrderStep - heading 1': {
		[CS_CZ]: 'Nakalkulované žaluzie',
		[SK_SK]: 'Nakalkulované žalúzie',
		[DE_DE]: 'Berechnete Jalousien',
		[HU_HU]: 'A megszerkesztett reluxák'
	},
	'OrderStep - button 1': {
		[CS_CZ]: 'Vrátit se ke kalkulaci',
		[SK_SK]: 'Vrátiť sa na kalkuláciu',
		[DE_DE]: 'Zurück zur Konfiguration',
		[HU_HU]: 'Vissza a szerkesztéshez'
	},
	'OrderStep - button 2': {
		[CS_CZ]: 'Přejít k objednávce',
		[SK_SK]: 'Prejsť k objednávke',
		[DE_DE]: 'Zur Bestellung',
		[HU_HU]: 'Átirányítás a megrendeléshez'
	},
	'OrderStep - phone': {
		[CS_CZ]: 'Vaše telefonní číslo',
		[SK_SK]: 'Vaše telefonní číslo',
		[DE_DE]: 'Ihre Telefonnummer',
		[HU_HU]: 'Telefonszám'
	},
	
	/* Misc */
	RIGHT: {
		[CS_CZ]: 'vlevo',
		[SK_SK]: 'vľavo',
		[DE_DE]: 'Links',
		[DE_AT]: 'Links',
		[HU_HU]: 'bal'
	},
	LEFT: {
		[CS_CZ]: 'vpravo',
		[SK_SK]: 'vpravo',
		[DE_DE]: 'Rechts',
		[DE_AT]: 'Rechts',
		[HU_HU]: 'jobb'
	},
	DOUBLE: {
		[CS_CZ]: 'dvojsklo',
		[SK_SK]: 'dvojsklo',
		[DE_DE]: 'Zweifachverglasung',
		[DE_AT]: 'Zweifachverglasung',
		[HU_HU]: 'kétrétegű üvegezés'
	},
	TRIPLE: {
		[CS_CZ]: 'trojsklo',
		[SK_SK]: 'trojsklo',
		[DE_DE]: 'Dreifachverglasung',
		[DE_AT]: 'Dreifachverglasung',
		[HU_HU]: 'háromrétegű üvegezés'
	},
	PLASTIC: {
		[CS_CZ]: 'plast',
		[SK_SK]: 'plast',
		[DE_DE]: 'Kunststoff',
		[DE_AT]: 'Kunststoff',
		[HU_HU]: 'műanyag'
	},
	WOOD: {
		[CS_CZ]: 'dřevo',
		[SK_SK]: 'drevo',
		[DE_DE]: 'Holz',
		[DE_AT]: 'Holz',
		[HU_HU]: 'fa'
	},
};


export default i18nStrings;
