import React from 'react';

import './Page.css';
import {Header} from './Header';
import {Main} from './Main';
import {Footer} from './Footer';
// import CookieLawBanner from './CookieLawBanner';
import styles from './App.css';
// import localStore from '../libs/localStore';

export default class App extends React.Component<{}, {}> {
	// state = {
	//  isCookieLawBannerHidden: true
	// };
	
	render() {
		return (
			<div className={styles.root}>
				<Header />
				{<Main />}
				{/*this.state.isCookieLawBannerHidden === true ? null : <CookieLawBanner handleHideBanner={this.hideCookieLawBanner} />*/}
				{<Footer />}
			</div>
		);
	}
	
	componentDidMount() {
		// if (!localStore.get('isCookieLawBannerHidden')) {
		//  this.setState({isCookieLawBannerHidden: false});
		// }
	}
	
	hideCookieLawBanner = () => {
		// localStore.set('isCookieLawBannerHidden', true);
		// this.setState({isCookieLawBannerHidden: true});
		// this.forceUpdate();
	};
}
