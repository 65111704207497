/* eslint-disable complexity */

import React, {memo} from 'react';

import {Frame} from './windows/Frame';
import {Lamella} from './windows/Lamella';
// import {Glass} from './windows/Glass';
import {Handle} from './windows/Handle';
// import {Hinge} from './windows/Hinge';

const OUTER_STROKE_WIDTH = 26;
const PADDING = 2;

export let Window = memo(({width, height, lamellaColor, profileColor, control, onResize}) => {
	let x = PADDING;
	let y = PADDING;
	
	let svgStyle = `
		path, line, rect {
		  vector-effect: non-scaling-stroke;
		}
		text {
		  font-family: Arial;
		  font-size: 20px;
		}`;
	
	let windowElements = (
		<>
			<Frame key="1" onResize={onResize} left={x} top={y} width={width} height={height} />
			{/* lamellas */}
			{do {
				let elements = [];
				
				for (let i = 0; i < Math.floor((height - 2 * OUTER_STROKE_WIDTH - 11) / 11); i++) {
					elements.push(
						<Lamella key={`${i}-a`} left={0} top={i * 11} width={width - 2 * OUTER_STROKE_WIDTH * 0.5 - 16} color={lamellaColor} />
					);
				}
				
				elements;
			}}
			
			{/* control */}
			{do {
				if (control === 'LEFT') {
					<>
						<rect
							key="2"
							x={x + OUTER_STROKE_WIDTH * 0.5 + width - 2 * OUTER_STROKE_WIDTH * 0.5 - 5}
							y={y + OUTER_STROKE_WIDTH * 0.5}
							fill="#c7c6c6"
							width={4}
							height={height * 0.75}
						/>
						<rect
							key="3"
							x={x + OUTER_STROKE_WIDTH * 0.5 + width - 2 * OUTER_STROKE_WIDTH * 0.5 - 5}
							y={y + OUTER_STROKE_WIDTH * 0.5 + height * 0.75 - 14}
							fill="#adadad"
							width={4}
							height={8}
						/>
					</>;
				} else {
					<>
						<rect
							key="2"
							x={x + OUTER_STROKE_WIDTH * 0.5 + 1}
							y={y + OUTER_STROKE_WIDTH * 0.5}
							fill="#c7c6c6"
							width={4}
							height={height * 0.75}
						/>
						<rect
							key="3"
							x={x + OUTER_STROKE_WIDTH * 0.5 + 1}
							y={y + OUTER_STROKE_WIDTH * 0.5 + height * 0.75 - 14}
							fill="#adadad"
							width={4}
							height={8}
						/>
					</>;
				}
			}}
			
			{/* top part */}
			<rect
				key="4"
				x={x + OUTER_STROKE_WIDTH * 0.5}
				y={y + OUTER_STROKE_WIDTH * 0.5}
				fill={profileColor}
				width={width - 2 * OUTER_STROKE_WIDTH * 0.5}
				height={22}
			/>
			<rect
				key="5"
				x={x + OUTER_STROKE_WIDTH * 0.5 + 8}
				y={y + OUTER_STROKE_WIDTH * 0.5 + 21}
				fill="#c7c6c6"
				width={width - 2 * OUTER_STROKE_WIDTH * 0.5 - 16}
				height={7}
				opacity={0.95}
			/>
			
			{/* handle */}
			<Handle
				key="6"
				left={x + OUTER_STROKE_WIDTH * 0.5 + (control === 'LEFT' ? 0 : width - 2 * OUTER_STROKE_WIDTH * 0.5)}
				top={y + OUTER_STROKE_WIDTH * 0.5 + height * 0.5}
				orientation={control === 'LEFT' ? 'TO_LEFT' : 'TO_RIGHT'}
			/>
		</>
	);
	
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${width + 2 * PADDING} ${height + 2 * PADDING}`}
		>
			<style>{svgStyle}</style>
			{windowElements}
		</svg>
	);
});
