import Stream from '../libs/Stream';

export interface SelectColorAction {
  // id: number;
  // category: number | null;
  // name: string;
  // sidesCount: 1 | 2;
  // priceModifiers: Array<{
  //   materialIds: Array<number>;
  //   sidesCounts: Array<number>;
  //   priceModifier: number;
  // }>;
}

let selectColorActionStream: Stream<SelectColorAction> = new Stream() as Stream<SelectColorAction>;

export default selectColorActionStream;
