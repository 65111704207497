import React, {useRef, useEffect} from 'react';

const OUTER_STROKE_WIDTH = 26;
const INNER_STROKE_WIDTH = 1;

export let Frame = ({left, top, width, height, onResize}) => {
  let rectElement = useRef(null);

  useEffect(() => {
    function handleResize() {
      if (onResize) {
        onResize(rectElement.current.getBoundingClientRect());
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onResize]);

  return (
    <>
      <rect ref={rectElement} x={left} y={top} fill="#ededed" width={width} height={height} />
      <rect
        ref={rectElement}
        x={left + OUTER_STROKE_WIDTH}
        y={top + OUTER_STROKE_WIDTH}
        fill="#FFFFFF"
        width={width - 2 * OUTER_STROKE_WIDTH}
        height={height - 2 * OUTER_STROKE_WIDTH}
        stroke="#dadada"
        strokeWidth={1}
      />
      {/*<rect
        ref={rectElement}
        x={left + OUTER_STROKE_WIDTH + INNER_STROKE_WIDTH}
        y={top + OUTER_STROKE_WIDTH + INNER_STROKE_WIDTH}
        fill="#FFFFFF"
        width={width - 2 * OUTER_STROKE_WIDTH - 2 * INNER_STROKE_WIDTH}
        height={height - 2 * OUTER_STROKE_WIDTH - 2 * INNER_STROKE_WIDTH}
      />
      {/*<rect ref={rectElement} x={left + STROKE_WIDTH / 2} y={top + STROKE_WIDTH / 2} fill={color} stroke="#EDEDED" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={width - STROKE_WIDTH} height={height - STROKE_WIDTH} />*/}
    </>
  );
};
