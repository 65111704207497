import React from 'react';

export let Lamella = ({left, top, width, color}) => {
  return (
    <g transform={`translate(${left},${top}) translate(22.5, 45)`}>
      <style type="text/css">{`
      .st0{fill:${color};}
      .st1{opacity:0.64;}
      .st2{fill:url(#SVGID_1_);stroke:url(#SVGID_2_);stroke-width:0.1;stroke-miterlimit:10;}
      .st3{fill:url(#SVGID_3_);stroke:url(#SVGID_4_);stroke-width:0.1;stroke-miterlimit:10;}
    `}</style>
      <path
        d={`M.62.04h${
          /*166.35*/ width
        }c.31 0 .57.26.57.57v7.38c0 .31-.26.57-.57.57H.62c-.31 0-.57-.26-.57-.57V.61C.05.3.31.04.62.04z`}
        className="st0"
      />
      <g className="st1">
        <linearGradient
          id="SVGID_1_"
          x1="83.795"
          x2="83.795"
          y1="12.32"
          y2="10.56"
          gradientTransform="matrix(1 0 0 -1 0 12.34)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#010101" stopOpacity="0.1"></stop>
          <stop offset="1" stopColor="#010101" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="SVGID_2_"
          x1="-1619.4"
          x2="-1451.81"
          y1="666.69"
          y2="666.69"
          gradientTransform="matrix(-1 0 0 1 -1451.81 -662.38)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7C7B7B"></stop>
          <stop offset="0.37" stopColor="#7E7E7E"></stop>
          <stop offset="1" stopColor="#7C7B7B"></stop>
        </linearGradient>
        <path
          d={`M${166.97 /* width + 0.62*/} 8.57H.62C.31 8.57.05 8.31.05 8V.62C.05.31.31.05.62.05h${
            /*166.35*/ width
          }c.31 0 .57.26.57.57V8c0 .31-.26.57-.57.57z`}
          className="st2"
        ></path>
      </g>

      <g className="st1">
        <linearGradient
          id="SVGID_3_"
          x1="83.795"
          x2="83.795"
          y1="6"
          y2="8.74"
          gradientTransform="matrix(1 0 0 -1 0 12.34)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FFF" stopOpacity="0.2"></stop>
          <stop offset="0.27" stopColor="#FFF" stopOpacity="0.18"></stop>
          <stop offset="0.56" stopColor="#FFF" stopOpacity="0.13"></stop>
          <stop offset="0.87" stopColor="#FFF" stopOpacity="0.05"></stop>
          <stop offset="1" stopColor="#FFF" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="SVGID_4_"
          x1="-1619.4"
          x2="-1451.81"
          y1="666.69"
          y2="666.69"
          gradientTransform="matrix(-1 0 0 1 -1451.81 -662.38)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7C7B7B"></stop>
          <stop offset="0.37" stopColor="#7E7E7E"></stop>
          <stop offset="1" stopColor="#7C7B7B"></stop>
        </linearGradient>
        <path
          d={`M${166.97 /* width + 0.62*/} 8.57H.62C.31 8.57.05 8.31.05 8V.62C.05.31.31.05.62.05h${
            /*166.35*/ width
          }c.31 0 .57.26.57.57V8c0 .31-.26.57-.57.57z`}
          className="st3"
        ></path>
      </g>
    </g>
  );
};
