import React, {memo, useCallback} from 'react';
import {Helmet} from 'react-helmet';

import Icon from './Icon';
import styles from './Header.css';
import orderStream from '../streams/orderStream';
import languageStream from '../streams/languageStream';
import link from '../utils/link';
import constants from '../internals/constants';
import isUrlInternal from '../internals/isUrlInternal';
import BrowserRouter from '../libs/BrowserRouter';
import router from '../streams/router';
import I18n from '../libs/I18n';
import config from '../config';
import getConvertedPrice from '../utils/getConvertedPrice';
import {useStream} from '../hooks/useStream';

const VAT_MODIFIER = config.VAT_MODIFIER; // eslint-disable-line prefer-destructuring

let browserRouter = new BrowserRouter(router);
let i18n = new I18n();

export let Header = memo(() => {
	useStream(languageStream);
	
	let handleLanguageSelectClick = useCallback((event) => {
		if (event.button !== 1) {
			let url = event.target.getAttribute('href');
			
			if (isUrlInternal(url)) {
				event.preventDefault();
				
				browserRouter.navigate(url);
			}
		}
	}, []);
	let order = useStream(orderStream);
	let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
	let countryCode = 'CS';
	let imageUrl = 'logo-2.png';
	
	if (languageStream.value.current === constants.SK_SK) {
		countryCode = 'SK';
		imageUrl = 'logo-2-sk.png';
	}
	
	if (languageStream.value.current === constants.DE_DE) {
		countryCode = 'DE';
		imageUrl = 'logo-2-de.png';
	}
	
	if (languageStream.value.current === constants.DE_AT) {
		countryCode = 'AT';
		imageUrl = 'logo-2-de.png';
	}
	
	if (languageStream.value.current === constants.HU_HU) {
		countryCode = 'HU';
		imageUrl = 'logo-hu.png';
	}
	
	return (
		<header className={styles.root}>
			<p className={styles.logo}>
				<img src={link('assets', imageUrl)} alt="Okna Hned" />
				{orderItem ? null : <span className={styles.claim}>{i18n.translate`Header - logo - claim`}</span>}
			</p>
			
			<div>
				{orderItem ? (
					<p>
						<span className={styles.configuring}>{i18n.translate`Header - window - configuring`}</span>
						<span className={styles.orderItemName}>
							{i18n.translate`Header - window - ${order.currentItem !== null ? order.currentItem + 1 : 1}`}
						</span>
						<span className={styles.separator}>|</span>
					</p>
				) : null}
				
				{orderItem ? (
					<p>
						{/*i18n.translate `Header - price - actual`*/}
						<span className={styles.orderItemPriceWithoutVatLabel}>{i18n.translate`Header - price - no VAT`}</span>
						<span className={styles.orderItemPriceWithoutVat}>
							{i18n.translate`Header - price - ${getConvertedPrice(orderItem.price,)}:c`}
						</span>
						<span className={styles.separator}>|</span>
						<span className={styles.orderItemPriceLabel}>{i18n.translate`Header - price - VAT`}</span>
						<span className={styles.orderItemPrice}>
							{i18n.translate`Header - price - ${getConvertedPrice(orderItem.price * VAT_MODIFIER[languageStream.value.current],)}:c`}
						</span>
					</p>
				) : null}
				
				<nav className={styles.languageSelect} onClick={handleLanguageSelectClick}>
					<p>{countryCode}</p>
					<ul>
						{countryCode === 'CS' ? null : (
							<li className={languageStream.value.current === constants.CS_CZ ? styles.isSelected : ''}>
								<a href={link(constants.CS_CZ)}>CS</a>
							</li>
						)}
						{countryCode === 'SK' ? null : (
							<li className={languageStream.value.current === constants.SK_SK ? styles.isSelected : ''}>
								<a href={link(constants.SK_SK)}>SK</a>
							</li>
						)}
						{countryCode === 'DE' ? null : (
							<li className={languageStream.value.current === constants.DE_DE ? styles.isSelected : ''}>
								<a href={link(constants.DE_DE)}>DE</a>
							</li>
						)}
						{countryCode === 'AT' ? null : (
							<li className={languageStream.value.current === constants.DE_AT ? styles.isSelected : ''}>
								<a href={link(constants.DE_AT)}>AT</a>
							</li>
						)}
						{countryCode === 'HU' ? null :
							<li className={languageStream.value.current === constants.HU_HU ? styles.isSelected : ''}>
								<a href={link(constants.HU_HU)}>HU</a>
							</li>
						}
					</ul>
				</nav>
				
				<p className={styles.youtube}>
					<a href={countryCode === 'DE' || countryCode === 'AT' ? "https://www.youtube.com/playlist?list=PL2SJ_I0N4u5Q72LbjK_nBNrJIQUSwWD_S" : "https://www.youtube.com/playlist?list=PL2SJ_I0N4u5QLiV_1ebE2PHiap0O6zxKL"} target="_blank" rel="noopener noreferrer">
						<Icon id="youtube" size="medium" />
						<span className={styles.youtubeLabel}>{i18n.translate`Header - videotutorial`}</span>
					</a>
				</p>
			</div>
			
			<Helmet>
				<title>{i18n.translate`html - head - title`}</title>
				<meta name="description" content={i18n.translate`html - head - meta description`} />
			</Helmet>
		</header>
	);
});
