import React from 'react';

import styles from './Main.css';
import {StepsList} from './StepsList';
import Button from './Button';
import WindowStep from './WindowStep';
import MaterialStep from './MaterialStep';
import GlassStep from './GlassStep';
import {ColorStep} from './ColorStep';
import AccessoriesStep from './AccessoriesStep';
import OrderStep from './OrderStep';
import Icon from './Icon';
import WindowVisualization from './WindowVisualization';
import Stream from '../libs/Stream';
import orderStream from '../streams/orderStream';
import appStateStream from '../streams/appStateStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import openStepActionStream from '../streams/openStepActionStream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';

let i18n = new I18n();

export class Main extends React.Component<{}, {}> {
	onOrderStream: Stream<{}>;
	onAppStateStream: Stream<{}>;
	onLanguageStream: Stream<{}>;
	
	componentDidMount() {
		this.onOrderStream = orderStream.on(() => {
			// requestAnimationFrame(() => this.forceUpdate());
			this.forceUpdate();
		});
		this.onAppStateStream = appStateStream.on(() => {
			// requestAnimationFrame(() => this.forceUpdate());
			this.forceUpdate();
		});
		this.onLanguageStream = languageStream.on(() => {
			// console.log('Main 1');
			// requestAnimationFrame(() => {
			//   console.log('Main 2');
			this.forceUpdate();
			// });
		});
		
		{
			/*this.handleStartClick();*/
		}
	}
	
	componentWillUnmount() {
		this.onOrderStream.end.push(true);
		this.onAppStateStream.end.push(true);
		this.onLanguageStream.end.push(true);
	}
	
	render() {
		let {openedStep} = appStateStream.value;
		let order = orderStream.value;
		let orderItem;
		
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}
		
		return (
			<main className={styles.root}>
				{orderItem && orderItem.width !== 0 && orderItem.height !== 0 ? (
					<div className={styles.visualization}>
						<WindowVisualization orderItem={orderItem} />
					</div>
				) : (
					<div className={styles.intro}>
						<h1 className={styles.appHeading}>{i18n.translate`Main - intro - heading`}</h1>
						<p className={styles.appDescription}>{i18n.translate`Main - intro - text 1`}</p>
						<p className={styles.buttons}>
							{orderItem ? null : (
								<Button
									type="full"
									color="inverse"
									size="normal"
									label={i18n.translate`Main - intro - button start`}
									handleClick={this.handleStartClick}
								/>
							)}
							{/*<Button type="outlined" size="normal" label={i18n.translate `Main - intro - button videotutorial`} link="https://www.youtube.com/watch?v=EPMNmCnhHOg" />*/}
						</p>
						<ul className={styles.appDescriptionItems}>
							<li>
								<Icon id="check" />
								<span>{i18n.translate`Main - intro - claim 1`}</span>
							</li>
							<li>
								<Icon id="check" />
								<span>{i18n.translate`Main - intro - claim 2`}</span>
							</li>
							<li>
								<Icon id="check" />
								<span>{i18n.translate`Main - intro - claim 3`}</span>
							</li>
							<li>
								<Icon id="check" />
								<span>{i18n.translate`Main - intro - claim 4`}</span>
							</li>
						</ul>
					</div>
				)}
				<StepsList />
				<ColorStep isOpen={openedStep === 'color'} />
				{/*<WindowStep isOpen={openedStep === 'window'} />
      <MaterialStep isOpen={openedStep === 'material'} />
      <GlassStep isOpen={openedStep === 'glass'} />
      <ColorStep isOpen={openedStep === 'color'} />
      <AccessoriesStep isOpen={openedStep === 'accessories'} />*/}
				<OrderStep isOpen={openedStep === 'order'} />
			</main>
		);
	}
	
	handleStartClick = () => {
		let order = orderStream.value;
		
		if (!order.items.length) {
			createOrderItemActionStream.push(true);
		}
		
		// openStepActionStream.push('window');
	};
}
