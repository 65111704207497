import React from 'react';

export let Handle = ({left, top, orientation}) => {
  if (orientation === 'TO_LEFT') {
    return (
      <g key="hndl" transform={`translate(${left},${top}) translate(-5.163, -23.8975)`}>
        <path
          fill="#b3b2b2"
          d="M5.986,23.411L5.986,23.411c-2.387,0-4.34-1.953-4.34-4.34V4.71c0-2.387,1.953-4.34,4.34-4.34
      s4.34,1.953,4.34,4.34v14.361C10.326,21.458,8.373,23.411,5.986,23.411"
        />
        <path
          fill="#dadada"
          d="M4.61,23.041H4.34c-2.387,0-4.34-1.953-4.34-4.34V4.34C0,1.953,1.953,0,4.34,0h0.27
      c2.387,0,4.341,1.953,4.341,4.34v14.361C8.951,21.088,6.997,23.041,4.61,23.041"
        />
        <path
          fill="#b3b2b2"
          d="M4.224,47.744L4.224,47.744c-1.633,0-2.968-1.336-2.968-2.969V10.436c0-1.633,1.335-2.968,2.968-2.968
      c1.632,0,2.968,1.335,2.968,2.968v34.339C7.192,46.408,5.856,47.744,4.224,47.744"
        />
        <path
          fill="#dadada"
          d="M2.968,47.795L2.968,47.795C1.335,47.795,0,46.459,0,44.827v-34.34c0-1.632,1.335-2.968,2.968-2.968
      c1.632,0,2.968,1.336,2.968,2.968v34.34C5.936,46.459,4.6,47.795,2.968,47.795"
        />
      </g>
    );
  } else if (orientation === 'TO_RIGHT') {
    return (
      <g key="hndl" transform={`translate(${left},${top}) translate(-5.163, -23.8975)`}>
        <path
          fill="#b3b2b2"
          d="M4.34,23.411L4.34,23.411c2.387,0,4.34-1.953,4.34-4.34V4.71c0-2.387-1.953-4.34-4.34-4.34S0,2.323,0,4.71
    l0,14.361C0,21.458,1.953,23.411,4.34,23.411"
        />
        <path
          fill="#dadada"
          d="M5.716,23.041h0.27c2.387,0,4.34-1.953,4.34-4.34V4.34c0-2.387-1.953-4.34-4.34-4.34l-0.27,0
    C3.329,0,1.375,1.953,1.375,4.34v14.361C1.375,21.088,3.329,23.041,5.716,23.041"
        />
        <path
          fill="#b3b2b2"
          d="M6.102,47.744L6.102,47.744c1.633,0,2.968-1.336,2.968-2.969V10.436c0-1.633-1.335-2.968-2.968-2.968
    c-1.632,0-2.968,1.335-2.968,2.968v34.339C3.134,46.408,4.47,47.744,6.102,47.744"
        />
        <path
          fill="#dadada"
          d="M7.358,47.795L7.358,47.795c1.633,0,2.968-1.336,2.968-2.968v-34.34c0-1.632-1.335-2.968-2.968-2.968
    c-1.632,0-2.968,1.336-2.968,2.968v34.34C4.39,46.459,5.726,47.795,7.358,47.795"
        />
      </g>
    );
  }

  return null;
};
