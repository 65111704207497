/* eslint-disable complexity, max-classes-per-file */

import React from 'react';

import styles from './Input.css';

const ENTER_KEY_CODE = 13;

export interface InputProps {
	type: 'text' | 'email' | 'number';
	size: 'normal' | 'small';
	id?: string;
	name?: string;
	autocomplete?: boolean;
	value?: string | number;
	min?: number;
	max?: number;
	unit?: string;
	isValid?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	handleChange?: (value: any) => void;
	handleSave?: (value: any) => void;
	validator?: (value: any) => void;
}

export default class Input extends React.Component<InputProps, {}> {
	render() {
		return this.props && typeof this.props.value !== 'undefined' ? (
			<span className={styles.root}>
        		<input
					key={this.props ? this.props.id || this.props.name : ''}
					className={
						styles.default +
						(this.props && this.props.isValid ? ' isValid' : '') +
						(this.props && this.props.isInvalid ? ' isInvalid' : '') +
						(this.props && this.props.isDisabled ? ' isDisabled' : ' isEnabled') +
						(this.props && this.props.unit ? ` ${styles.hasUnit}` : '') +
						(this.props && this.props.size === 'small' ? ' isSmall' : '')
					}
					type={this.props ? this.props.type : 'text'}
					min={this.props && typeof this.props.min !== 'undefined' ? this.props.min : ''}
					max={this.props && typeof this.props.max !== 'undefined' ? this.props.max : ''}
					name={this.props ? this.props.name || this.props.id : ''}
					id={this.props ? this.props.id || this.props.name : ''}
					value={this.props ? `${this.props.value}` : ''}
					autoComplete={this.props ? this.props.autocomplete : false}
					disabled={this.props ? this.props.isDisabled : false}
					onBlur={this.handleFocusOut}
					onChange={this.handleInput}
					onKeyDown={this.handleKeyDown}
				/>
				{this.props && this.props.unit ? <span className={styles.unit}>{this.props.unit}</span> : null}
      		</span>
		) : (
			<span className={styles.root}>
				<input
					key={this.props ? this.props.id || this.props.name : ''}
					className={
						styles.default +
						(this.props && this.props.isValid ? ' isValid' : '') +
						(this.props && this.props.isInvalid ? ' isInvalid' : '') +
						(this.props && this.props.isDisabled ? ' isDisabled' : ' isEnabled') +
						(this.props && this.props.unit ? ` ${styles.hasUnit}` : '')
					}
					type={this.props ? this.props.type : 'text'}
					min={this.props && typeof this.props.min !== 'undefined' ? this.props.min : ''}
					max={this.props && typeof this.props.max !== 'undefined' ? this.props.max : ''}
					name={this.props ? this.props.name || this.props.id : ''}
					id={this.props ? this.props.id || this.props.name : ''}
					value=""
					autoComplete={this.props ? this.props.autocomplete : false}
					disabled={this.props ? this.props.isDisabled : false}
					onBlur={this.handleFocusOut}
					onChange={this.handleInput}
					onKeyDown={this.handleKeyDown}
				/>
				{this.props && this.props.unit ? <span className={styles.unit}>{this.props.unit}</span> : null}
      		</span>
		);
	}
	
	handleInput = (event) => {
		if (this.props && this.props.handleChange) {
			this.props.handleChange(this.validate(event.target.value));
		}
	};
	
	handleFocusOut = (event) => {
		if (this.props && this.props.handleSave) {
			this.props.handleSave(this.validate(event.target.value));
		}
	};
	
	handleKeyDown = (event) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			if (this.props && this.props.handleSave) {
				this.props.handleSave(this.validate(event.target.value));
			}
		}
	};
	
	validate(value) {
		return this.props && this.props.validator ? this.props.validator(value) : value;
	}
}
