import _ from 'lodash';

import data from '../data';

let defaultProfileColor = _.find(data.colors, {id: 1, type: 'PROFILE'});
let defaultLamellaColor = _.find(data.colors, {id: 5, type: 'LAMELLA'});

export function createEmptyOrderItem() {
  return {
    count: 1,
    price: 0,
    width: 0,
    height: 0,
    profileColor: defaultProfileColor,
    lamellaColor: defaultLamellaColor,
    control: 'RIGHT',
    glass: 'DOUBLE',
    material: 'PLASTIC',
  };
}
