import moment from 'moment';

import I18n from '../libs/I18n';
import Stream from '../libs/Stream';
import routeStream from './routeStream';
import constants from '../internals/constants';
import config from '../config';

export interface LanguageStreamValue {
	previous: string | null;
	current: string | null;
}

// init i18n
let i18n = new I18n();

(global as any).i18n = i18n;

let origin = constants.CS;
let locale = constants.CS_CZ;
let currency = 'CZK';

if (global.location.origin.indexOf('.sk') >= 0) {
	origin = constants.SK;
	locale = constants.SK_SK;
	currency = 'EUR';
}

if (global.location.origin.indexOf('.de') >= 0) {
	origin = constants.DE;
	locale = constants.DE_DE;
	currency = 'EUR';
}

if (global.location.origin.indexOf('.at') >= 0) {
	origin = constants.DE;
	locale = constants.DE_AT;
	currency = 'EUR';
}

if (global.location.origin.indexOf('.hu') >= 0) {
	origin = constants.HU;
	locale = constants.HU_HU;
	currency = 'HUF';
}

moment.locale(origin);
i18n.use({
	strings: config.i18nStrings,
	locale,
	currency,
});

let languageStram: Stream<LanguageStreamValue> = new Stream({
	previous: null,
	current: null,
});

(global as any).languageStram = languageStram;

languageStram.combine(
	(changed, self, dependency) => {
		let value = self.value;
		let {language} = dependency.value;
		
		if (language !== value.current && (language === constants.CS_CZ || language === constants.SK_SK || language === constants.DE_DE || language === constants.DE_AT || language === constants.HU_HU)) {
			let newValue = {
				current: language,
				previous: value.current,
			};
			
			if (language === constants.CS_CZ) {
				moment.locale(constants.CS);
				i18n.use({
					strings: config.i18nStrings,
					locale: constants.CS_CZ,
					currency: 'CZK',
				});
			} else if (language === constants.SK_SK) {
				moment.locale(constants.SK);
				i18n.use({
					strings: config.i18nStrings,
					locale: constants.SK_SK,
					currency: 'EUR',
				});
			} else if (language === constants.DE_DE) {
				moment.locale(constants.DE);
				i18n.use({
					strings: config.i18nStrings,
					locale: constants.DE_DE,
					currency: 'EUR',
				});
			} else if (language === constants.DE_AT) {
				moment.locale(constants.DE);
				i18n.use({
					strings: config.i18nStrings,
					locale: constants.DE_DE, // use DE_DE for DE_AT
					currency: 'EUR',
				});
			} else if (language === constants.HU_HU) {
				moment.locale(constants.HU);
				i18n.use({
					strings: config.i18nStrings,
					locale: constants.HU_HU,
					currency: 'HUF'
				});
			}
			
			self.push(newValue);
		}
	},
	[routeStream],
);

export default languageStram;
