/* eslint-disable complexity */

import $ from 'jquery';
import _ from 'lodash';
import Bluebird from 'bluebird';

import createOrderItemActionStream from './createOrderItemActionStream';
import selectOrderItemActionStream from './selectOrderItemActionStream';
import increaseOrderItemCountActionStream from './increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from './decreaseOrderItemCountActionStream';
import destroyOrderItemActionStream from './destroyOrderItemActionStream';
import dataStream from './dataStream';
import selectWindowActionStream from './selectWindowActionStream';
import selectMaterialActionStream from './selectMaterialActionStream';
import selectGlassActionStream from './selectGlassActionStream';
import selectColorActionStream from './selectColorActionStream';
import selectAccessoriesActionStream from './selectAccessoriesActionStream';
import updatePhoneActionStream from './updatePhoneActionStream';
import {setWindowSizeActionStream} from './setWindowSizeActionStream';
import Stream from '../libs/Stream';
import {Order} from '../types/structure';
import setOrderItemPrice from '../utils/setOrderItemPrice';
import findDefaultGlass from '../utils/findDefaultGlass';
import findDefaultMaterial from '../utils/findDefaultMaterial';
import findDefaultColor from '../utils/findDefaultColor';
import languageStream from './languageStream';
import routeStream from './routeStream';
import BrowserRouter from '../libs/BrowserRouter';
import router from '../streams/router';
import link from '../utils/link';
import uuid4 from '../internals/uuid4';
import {createEmptyOrderItem} from '../utils/createEmptyOrderItem';
import {setWindowSpecificationActionStream} from './setWindowSpecificationActionStream';

const UUID_REGEXP = /[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-4[0-9A-Za-z]{3}-[89ABab][0-9A-Za-z]{3}-[0-9A-Za-z]{12}/;

let browserRouter = new BrowserRouter(router);

function deserializeOrder(serializedOrder) {
  if (!serializedOrder) {
    return null;
  }

  let order = null;

  try {
    order = JSON.parse(decodeURIComponent(atob(serializedOrder)));
  } catch (error) {
    return null;
  }

  return order;
}

let orderStream: Stream<Order> = new Stream({
  uuid: uuid4(),
  items: [],
  currentItem: null,
  language: null,
  phone: '',
});

(global as any).orderStream = orderStream;

orderStream
  .combine(
    async (changed, self) => {
      let order = self.value;
      let isOrderUpdated = false;

      if (changed.includes(languageStream)) {
        order.language = languageStream.value.current;

        isOrderUpdated = true;
      }

      if (changed.includes(routeStream)) {
        let response;

        if (UUID_REGEXP.test(routeStream.value.order || '')) {
          try {
            response = await Bluebird.resolve(
              $.ajax({
                url: 'https://www.okna-hned.cz/scripts/getInfo.php',
                type: 'POST',
                data: {
                  data: routeStream.value.order,
                },
              }),
            );
          } catch (error) {}

          if (response) {
            try {
              order = JSON.parse(response);

              isOrderUpdated = true;
            } catch (error) {
              console.warn(error);
            }
          }
        } else {
          let newOrder = deserializeOrder(response || routeStream.value.order);

          if (newOrder) {
            order = newOrder;
          }

          isOrderUpdated = true;
        }
      }

      if (changed.includes(updatePhoneActionStream)) {
        order.phone = updatePhoneActionStream.value;

        isOrderUpdated = true;
      }

      if (changed.includes(createOrderItemActionStream)) {
        order.items.push(createEmptyOrderItem());

        order.currentItem = order.items.length - 1;
        isOrderUpdated = true;
      } else if (changed.includes(destroyOrderItemActionStream)) {
        let index = destroyOrderItemActionStream.value;

        if (index >= 0 && index <= order.items.length - 1) {
          order.items.splice(index, 1);

          if (order.currentItem !== null && index >= order.currentItem) {
            order.currentItem--;

            if (order.currentItem < 0) {
              order.currentItem = 0;
            }
          }

          if (order.currentItem > order.items.length - 1) {
            order.currentItem--;
          }

          if (!order.items.length) {
            order.currentItem = null;
          }

          isOrderUpdated = true;
        }
      } else if (changed.includes(selectOrderItemActionStream)) {
        let index = selectOrderItemActionStream.value;

        if (index >= 0 && index <= order.items.length - 1) {
          order.currentItem = index;

          // update price
          if (order.currentItem !== null) {
            setOrderItemPrice(order.items[order.currentItem]);
          }

          isOrderUpdated = true;
        }
      } else if (changed.includes(increaseOrderItemCountActionStream)) {
        let index = increaseOrderItemCountActionStream.value;

        if (index >= 0 && index <= order.items.length - 1) {
          order.items[index].count++;

          // update price
          if (order.currentItem !== null) {
            setOrderItemPrice(order.items[order.currentItem]);
          }

          isOrderUpdated = true;
        }
      } else if (changed.includes(decreaseOrderItemCountActionStream)) {
        let index = decreaseOrderItemCountActionStream.value;

        if (index >= 0 && index <= order.items.length - 1 && order.items[index].count >= 2) {
          order.items[index].count--;

          // update price
          if (order.currentItem !== null) {
            setOrderItemPrice(order.items[order.currentItem]);
          }

          isOrderUpdated = true;
        }
      } else if (changed.length) {
        let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];

        if (orderItem && changed.includes(setWindowSizeActionStream)) {
          let {action, payload} = setWindowSizeActionStream.value;

          if (action === 'SET_WIDTH') {
            orderItem.width = parseInt(payload, 10);

            setOrderItemPrice(orderItem);
          }

          if (action === 'SET_HEIGHT') {
            orderItem.height = parseInt(payload, 10);

            setOrderItemPrice(orderItem);
          }

          isOrderUpdated = true;
        }

        if (orderItem && changed.includes(selectColorActionStream)) {
          let {action, payload} = selectColorActionStream.value;

          if (action === 'SET_PROFILE_COLOR') {
            orderItem.profileColor = payload;
          }

          if (action === 'SET_LAMELLA_COLOR') {
            orderItem.lamellaColor = payload;
          }

          isOrderUpdated = true;
        }

        if (orderItem && changed.includes(setWindowSpecificationActionStream)) {
          let {action, payload} = setWindowSpecificationActionStream.value;

          if (action === 'SET_CONTROL') {
            orderItem.control = payload;
          }

          if (action === 'SET_GLASS') {
            orderItem.glass = payload;
          }

          if (action === 'SET_MATERIAL') {
            orderItem.material = payload;
          }

          isOrderUpdated = true;
        }
      }

      if (process.env.NODE_ENV === 'development') {
        browserRouter.navigate(
          link(languageStream.value.current || '', 'order', btoa(encodeURIComponent(JSON.stringify(order)))),
          {trigger: false, replace: true},
        );
      }

      if (isOrderUpdated) {
        self.push({...order});
      }
    },
    [
      languageStream,
      routeStream,
      createOrderItemActionStream,
      destroyOrderItemActionStream,
      selectOrderItemActionStream,
      increaseOrderItemCountActionStream,
      decreaseOrderItemCountActionStream,
      updatePhoneActionStream,
      setWindowSizeActionStream,
      selectColorActionStream,
      setWindowSpecificationActionStream /*selectWindowActionStream, selectMaterialActionStream, selectGlassActionStream, selectColorActionStream, selectAccessoriesActionStream*/,
    ],
  )
  .immediate();

createOrderItemActionStream.push(true);

export default orderStream;
