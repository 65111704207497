import _ from 'lodash';

import config from '../config';
import dataStream from '../streams/dataStream';

const MARGIN = config.MARGIN;

export default function getOrderItemPrice(orderItem) {
  let price = 0;
  let totalPrice = 0;

  if (!(orderItem.width && orderItem.height)) {
    return {price, totalPrice};
  }

  dataStream.value.prices.forEach((pricingItem) => {
    if (
      orderItem.width >= pricingItem.widths[0] &&
      orderItem.width <= pricingItem.widths[1] &&
      orderItem.height >= pricingItem.heights[0] &&
      orderItem.height <= pricingItem.heights[1]
    ) {
      price = pricingItem.price;
    }
  });

  price = price * MARGIN;
  totalPrice = price * orderItem.count;

  return {price, totalPrice};
}
