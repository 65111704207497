/* eslint-disable complexity */

import React, {memo, useCallback, useState} from 'react';
import _ from 'lodash';

import styles from './StepsList.css';
import Stream from '../libs/Stream';
import orderStream from '../streams/orderStream';
import dataStream from '../streams/dataStream';
import openStepActionStream from '../streams/openStepActionStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import selectOrderItemActionStream from '../streams/selectOrderItemActionStream';
import increaseOrderItemCountActionStream from '../streams/increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from '../streams/decreaseOrderItemCountActionStream';
import {setWindowSizeActionStream} from '../streams/setWindowSizeActionStream';
import {OrderItem} from '../types/structure';
import Button from './Button';
import {RadioButton} from './RadioButton';
import Icon from './Icon';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import config from '../config';
import Input from './Input';
import {useStream} from '../hooks/useStream';
import {setWindowSpecificationActionStream} from '../streams/setWindowSpecificationActionStream';
import link from '../utils/link';

const ALT_NAME_HEIGHT = config.ALT_NAME_HEIGHT;

let i18n = new I18n();

export interface OrderItemLinkProps {
	index: number;
	orderItem: OrderItem;
	handleShow: (index: number) => void;
	handleAdd: (index: number) => void;
	handleRemove: (index: number) => void;
	handleDelete: (index: number) => void;
}

export class OrderItemLink extends React.Component<OrderItemLinkProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}
		
		let order = orderStream.value;
		
		return (
			<div className={styles.orderItemLink + (this.props.index === order.currentItem ? ` ${styles.isSelected}` : '')}>
				<span className={styles.orderItemLinks}>
					<Button type="full" label="+" size="small" handleClick={this.handleAddClick} />
					<Button type="full" label="-" size="small" handleClick={this.handleRemoveClick} />
					<Button type="full" icon="bin" size="small" handleClick={this.handleDeleteClick} />
				</span>
				<span className={styles.orderItemCount}>{this.props.orderItem.count} ks</span>
				<span className={styles.orderItemName}>
					<a href="#" onClick={this.handleShowClick}>{i18n.translate`OrderStep - order item link - heading - ${this.props.index + 1}`}</a>
        		</span>
				<span className={styles.orderItemLinkIcon}>
					<Icon id="cog" />
				</span>
			</div>
		);
	}
	
	handleShowClick = (event) => {
		event.preventDefault();
		
		if (this.props && this.props.handleShow) {
			this.props.handleShow(this.props.index);
		}
	};
	
	handleAddClick = (event) => {
		// event.preventDefault();
		
		if (this.props && this.props.handleAdd) {
			this.props.handleAdd(this.props.index);
		}
	};
	
	handleRemoveClick = (event) => {
		// event.preventDefault();
		
		if (this.props && this.props.handleRemove) {
			this.props.handleRemove(this.props.index);
		}
	};
	
	handleDeleteClick = (event) => {
		// event.preventDefault();
		
		if (this.props && this.props.handleDelete) {
			this.props.handleDelete(this.props.index);
		}
	};
}

export let StepsList = memo(() => {
	let {current: language} = useStream(languageStream);
	
	let [isModal1Visible, setIsModal1Visible] = useState(false);
	let [isModal2Visible, setIsModal2Visible] = useState(false);
	
	let handleHeightChange = useCallback((value) => {
		setWindowSizeActionStream.push({
			action: 'SET_HEIGHT',
			payload: value || 0,
		});
	}, []);
	
	let handleWidthChange = useCallback((value) => {
		setWindowSizeActionStream.push({
			action: 'SET_WIDTH',
			payload: value || 0,
		});
	}, []);
	
	let handleControlChange = useCallback((value) => {
		setWindowSpecificationActionStream.push({
			action: 'SET_CONTROL',
			payload: value,
		});
	}, []);
	
	let handleGlassChange = useCallback((value) => {
		setWindowSpecificationActionStream.push({
			action: 'SET_GLASS',
			payload: value,
		});
	}, []);
	
	let handleMaterialChange = useCallback((value) => {
		setWindowSpecificationActionStream.push({
			action: 'SET_MATERIAL',
			payload: value,
		});
	}, []);
	
	let handleAddNewOrderItem = useCallback(() => {
		createOrderItemActionStream.push(true);
	}, []);
	
	let handleDeleteOrderItem = useCallback((index) => {
		destroyOrderItemActionStream.push(index);
	}, []);
	
	let handleShowOrderItem = useCallback((index) => {
		selectOrderItemActionStream.push(index);
	}, []);
	
	let handleAddOrderItem = useCallback((index) => {
		increaseOrderItemCountActionStream.push(index);
	}, []);
	
	let handleRemoveOrderItem = useCallback((index) => {
		decreaseOrderItemCountActionStream.push(index);
	}, []);
	
	let order = useStream(orderStream);
	let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
	
	let handleColorStepClick = useCallback(() => {
		if (!orderItem) {
			return;
		}
		
		openStepActionStream.push('color');
	}, [orderItem]);
	
	let handleOrderStepClick = useCallback(() => {
		if (!orderItem) {
			return;
		}
		
		openStepActionStream.push('order');
	}, [orderItem]);
	
	return (
		<>
			<nav className={styles.root}>
				<ol className={styles.steps}>
					<li key="1" className={`${styles.step} ${styles.hasOverlay} ${orderItem ? '' : styles.isEmpty}`}>
						{/*<span
              className={`${styles.startHere} ${
                orderItem && order.items.length < 2 && orderItem.width < 1 && orderItem.height < 1
                  ? styles.isVisible
                  : ''
              }`}
            >
              Začněte zde
            </span>*/}
						<div className={styles.stepWrapper}>
							<p className={styles.stepHeading}>{i18n.translate`StepsList - window size`}</p>
							
							<div className={styles.stepContent}>
								{orderItem ? (
									<>
										<div className={styles.windowWidth}>
											<label htmlFor="width">{i18n.translate`StepsList - width`}</label>
											<Input
												type="number"
												size="small"
												name="width"
												min={300}
												max={9999}
												unit="mm"
												value={`${orderItem.width}`}
												handleChange={handleWidthChange}
											/>
										</div>
										<div className={styles.windowHeight}>
											<label htmlFor="height">{i18n.translate`StepsList - height`}</label>
											<Input
												type="number"
												size="small"
												name="height"
												min={300}
												max={9999}
												unit="mm"
												value={`${orderItem.height}`}
												handleChange={handleHeightChange}
											/>
										</div>
										{orderItem.width > 2500 || orderItem.height > 2500 ? (
											<div>
												<p>{i18n.translate`StepsList - max width`}</p>
											</div>
										) : null}
										{orderItem.width < 300 || orderItem.height < 300 ? (
											<div>
												<p>{i18n.translate`StepsList - max height`}</p>
											</div>
										) : null}
										<div>
											<div className={styles.needHelpButton}>
												<p
													onClick={() => setIsModal1Visible(!isModal1Visible)}
												>{i18n.translate`StepsList - how to measure`}</p>
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
					</li>
					
					<li key="2" className={`${styles.step} ${styles.hasOverlay} ${orderItem ? '' : styles.isEmpty}`}>
						<div className={styles.stepWrapper}>
							<p className={styles.stepHeading}>{i18n.translate`StepsList - color`}</p>
							
							<div className={styles.stepContent}>
								{orderItem ? (
									<>
										<p className={styles.profileColor}>
											<span>{i18n.translate`StepsList - color - profile`}:</span>
											<span>{orderItem.profileColor?.name?.[language]}</span>
										</p>
										<p className={styles.lamellaColor}>
											<span>{i18n.translate`StepsList - color - lamella`}:</span>
											<span>{orderItem.lamellaColor?.name?.[language]}</span>
										</p>
										<Button
											type="full"
											size="small"
											label={i18n.translate`StepsList - change color`}
											isDisabled={false}
											handleClick={handleColorStepClick}
										/>
									</>
								) : null}
							</div>
						</div>
					</li>
					
					<li key="3" className={`${styles.step} ${styles.hasOverlay} ${orderItem ? '' : styles.isEmpty}`}>
						<div className={styles.stepWrapper}>
							<p className={styles.stepHeading}>{i18n.translate`StepsList - windows specification`}</p>
							
							<div className={styles.stepContent}>
								{orderItem ? (
									<>
										<div>
											<label>{i18n.translate`StepsList - control`}</label>
											<RadioButton
												name="control"
												items={[
													{
														value: 'RIGHT',
														label: i18n.translate`RIGHT`,
														isChecked: orderItem.control === 'RIGHT',
														isDisabled: false,
													},
													{
														value: 'LEFT',
														label: i18n.translate`LEFT`,
														isChecked: orderItem.control === 'LEFT',
														isDisabled: false,
													},
												]}
												onChange={handleControlChange}
											/>
										</div>
										<div>
											<label>{i18n.translate`StepsList - glass`}</label>
											<RadioButton
												name="glass"
												items={[
													{
														value: 'DOUBLE',
														label: i18n.translate`DOUBLE`,
														isChecked: orderItem.glass === 'DOUBLE',
														isDisabled: false,
													},
													{
														value: 'TRIPLE',
														label: i18n.translate`TRIPLE`,
														isChecked: orderItem.glass === 'TRIPLE',
														isDisabled: false,
													},
												]}
												onChange={handleGlassChange}
											/>
										</div>
										<div>
											<label>{i18n.translate`StepsList - material`}</label>
											<RadioButton
												name="material"
												items={[
													{
														value: 'PLASTIC',
														label: i18n.translate`PLASTIC`,
														isChecked: orderItem.material === 'PLASTIC',
														isDisabled: false,
													},
													{
														value: 'WOOD',
														label: i18n.translate`WOOD`,
														isChecked: orderItem.material === 'WOOD',
														isDisabled: false,
													},
												]}
												onChange={handleMaterialChange}
											/>
										</div>
										<div>
											<div className={styles.needHelpButton}>
												<p
													onClick={() => setIsModal2Visible(!isModal2Visible)}
												>{i18n.translate`StepsList - windows specification why`}</p>
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
					</li>
					
					<li key="4" className={styles.step + ` ${order.items.length ? '' : styles.isEmpty}`}>
						<div className={styles.stepWrapper}>
							<p className={styles.stepHeading}>{i18n.translate`StepsList - my sunblinds`}</p>
							
							<div className={styles.stepContent}>
								<ul className={styles.stepList}>
									{order.items.length
										? order.items.map((orderItem, index) => (
											<li key={index}>
												<OrderItemLink
													index={index}
													orderItem={orderItem}
													handleShow={handleShowOrderItem}
													handleAdd={handleAddOrderItem}
													handleRemove={handleRemoveOrderItem}
													handleDelete={handleDeleteOrderItem}
												/>
											</li>
										))
										: null}
								</ul>
								
								{orderStream.value.items && orderStream.value.items.length ? (
									<p className={styles.stepButton}>
										<Button
											type="full"
											size="small"
											label={i18n.translate`StepsList - add sunblinds`}
											overlayLabel={i18n.translate`StepsList - add sunblinds overlay`}
											handleClick={handleAddNewOrderItem}
										/>
									</p>
								) : null}
							</div>
						</div>
					</li>
					
					<li key="5" className={styles.step + ` ${order.items.length ? '' : styles.isEmpty}`}>
						<div className={styles.stepWrapper}>
							<p className={styles.stepHeading}>{i18n.translate`StepsList - order`}</p>
							
							<div className={styles.stepContent}>
								{orderStream.value.items.length ? (
									<>
										<p>{i18n.translate`StepsList - order - info`}</p>
										<p className={styles.stepButton}>
											<Button
												type="full"
												label={i18n.translate`StepsList - order - button`}
												isDisabled={
													!orderStream.value.items.length ||
													order.items.some(
														(item) => item.width === 0 || item.height === 0 || item.width > 2500 || item.height > 2500,
													)
												}
												handleClick={handleOrderStepClick}
											/>
										</p>
									</>
								) : null}
							</div>
						</div>
					</li>
				</ol>
			</nav>
			<div className={styles.needHelpModal + (isModal1Visible ? ` ${styles.isOpen}` : '')}>
				<div className={styles.needHelpModalWrapper}>
					<h3 className={styles.heading}>{i18n.translate`StepsList - how to measure`}</h3>
					<span className={styles.closeButton}>
            			<Button type="invisible" size="large" icon="cross" handleClick={() => setIsModal1Visible(false)} />
          			</span>
					<figure>
						<iframe width="560" height="315" src="https://www.youtube.com/embed/nrv_yFmRY-0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</figure>
					<p>{i18n.translate`How to measure - text 1`}</p>
					<figure>
						<img src={link('assets', 'okno-vyrobni-rozmer.jpg')} alt="" />
					</figure>
					<p>{i18n.translate`How to measure - text 2`}</p>
					<p>{i18n.translate`How to measure - text 3`}</p>
					<p>{i18n.translate`How to measure - text 4`}</p>
					<figure>
						<img src={link('assets', 'zamereni-sklo.jpg')} alt="" />
					</figure>
					<p>{i18n.translate`How to measure - text 5`}</p>
					<p>{i18n.translate`How to measure - text 6`}</p>
					<p>{i18n.translate`How to measure - text 7`}</p>
					<figure>
						<img src={link('assets', 'zamereni-okno-3x.jpg')} alt="" />
					</figure>
					<p>{i18n.translate`How to measure - text 8`}</p>
				</div>
			</div>
			<div className={styles.needHelpModal + (isModal2Visible ? ` ${styles.isOpen}` : '')}>
				<div className={styles.needHelpModalWrapper}>
					<h3 className={styles.heading}>{i18n.translate`StepsList - windows specification why`}</h3>
					<span className={styles.closeButton}>
            			<Button type="invisible" size="large" icon="cross" handleClick={() => setIsModal2Visible(false)} />
          			</span>
					<h4 className={styles.subheading}>{i18n.translate`StepsList - control`}</h4>
					<p>{i18n.translate`Windows specification why - text 1`}</p>
					<p>{i18n.translate`Windows specification why - text 2`}</p>
					<h4 className={styles.subheading}>{i18n.translate`StepsList - glass`}</h4>
					<p>{i18n.translate`Windows specification why - text 3`}</p>
					<h4 className={styles.subheading}>{i18n.translate`StepsList - material`}</h4>
					<p>{i18n.translate`Windows specification why - text 4`}</p>
				</div>
			</div>
		</>
	);
});
