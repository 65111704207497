/* eslint-disable complexity */

import _ from 'lodash';
import React from 'react';

import styles from './OrderStep.css';
import Button from './Button';
import Input from './Input';
import WindowVisualization from './WindowVisualization';
import ButtonGroup from './ButtonGroup';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import Stream from '../libs/Stream';
import {OrderItem} from '../types/structure';
import openStepActionStream from '../streams/openStepActionStream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import Icon from './Icon';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import selectOrderItemActionStream from '../streams/selectOrderItemActionStream';
import increaseOrderItemCountActionStream from '../streams/increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from '../streams/decreaseOrderItemCountActionStream';
import updatePhoneActionStream from '../streams/updatePhoneActionStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import link from '../utils/link';
import uuid4 from '../internals/uuid4';
import config from '../config';

const ALT_NAME_HEIGHT = config.ALT_NAME_HEIGHT;
const VAT_MODIFIER = config.VAT_MODIFIER; // eslint-disable-line prefer-destructuring

let i18n = new I18n();

export interface OrderItemLinkProps {
	index: number;
	orderItem: OrderItem;
	handleShow: (index: number) => void;
	handleAdd: (index: number) => void;
	handleRemove: (index: number) => void;
	handleDelete: (index: number) => void;
}

export class OrderItemLink extends React.Component<OrderItemLinkProps, {}> {
	onLanguageStream: Stream<{}>;
	
	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// requestAnimationFrame(() => this.forceUpdate());
		});
	}
	
	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
	}
	
	render() {
		if (!this.props) {
			return null;
		}
		
		let order = orderStream.value;
		
		return (
			<div className={styles.orderItemLink + (this.props.index === order.currentItem ? ` ${styles.isSelected}` : '')}>
				{this.props.index === order.currentItem ? (<span className={styles.orderItemLinkIcon}><Icon id="cog" /></span>) : null}
				<span className={styles.orderItemName}>{i18n.translate`OrderStep - order item link - heading - ${this.props
					.index + 1}`}</span>
				<span className={styles.orderItemLinks}>
					<a href="#" onClick={this.handleAddClick}>{i18n.translate`OrderStep - order item link - button 1`}</a>
					<a href="#" onClick={this.handleRemoveClick}>{i18n.translate`OrderStep - order item link - button 2`}</a>
					<a href="#" onClick={this.handleDeleteClick}>{i18n.translate`OrderStep - order item link - button 3`}</a>
					<a href="#" onClick={this.handleShowClick}>{i18n.translate`OrderStep - order item link - button 4`}</a>
				</span>
			</div>
		);
	}
	
	handleShowClick = (event) => {
		event.preventDefault();
		
		if (this.props && this.props.handleShow) {
			this.props.handleShow(this.props.index);
		}
	};
	
	handleAddClick = (event) => {
		event.preventDefault();
		
		if (this.props && this.props.handleAdd) {
			this.props.handleAdd(this.props.index);
		}
	};
	
	handleRemoveClick = (event) => {
		event.preventDefault();
		
		if (this.props && this.props.handleRemove) {
			this.props.handleRemove(this.props.index);
		}
	};
	
	handleDeleteClick = (event) => {
		event.preventDefault();
		
		if (this.props && this.props.handleDelete) {
			this.props.handleDelete(this.props.index);
		}
	};
}

export interface OrderItemDetailProps {
	orderItem: OrderItem;
	index: number;
}

export class OrderItemDetail extends React.Component<OrderItemDetailProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}
		
		let {windowTypes} = dataStream.value;
		let orderItem = this.props.orderItem;
		let windowType;
		
		if (orderItem.window) {
			windowType = _.find(windowTypes, {id: orderItem.window.typeId});
		}
		
		return (
			<div className={styles.orderItemDetail}>
				<h4 className={styles.orderItemDetailHeading}>
					<OrderItemLink
						index={this.props.index}
						orderItem={orderItem}
						handleShow={this.handleShowOrderItem}
						handleAdd={this.handleAddOrderItem}
						handleRemove={this.handleRemoveOrderItem}
						handleDelete={this.handleDeleteOrderItem}
					/>
				</h4>
				
				<ul className={styles.orderItemDetailProperties}>
					<li key="1" className={styles.orderItemDetailProperty}>
						<span className={styles.orderItemDetailPropertyLabel}>
							{i18n.translate`OrderStep - order item detail - property label 1`}
						</span>
						<span className={styles.orderItemDetailPropertyValue}>
							{i18n.translate`OrderStep - order item detail - property value - ${getConvertedPrice(orderItem.price,)}:c`}
						</span>
					</li>
					
					{orderItem.width && orderItem.height ? (
						<li key="2" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 2`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{`${orderItem.width} × ${orderItem.height} mm`}
							</span>
						</li>
					) : null}
					
					{orderItem.profileColor ? (
						<li key="3" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 3`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{orderItem.profileColor.name[languageStream.value.current]}
							</span>
						</li>
					) : null}
					
					{orderItem.lamellaColor ? (
						<li key="4" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 4`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{orderItem.lamellaColor.name[languageStream.value.current]}
							</span>
						</li>
					) : null}
					
					{orderItem.control ? (
						<li key="5" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 5`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{config.i18nStrings[orderItem.control][languageStream.value.current]}
							</span>
						</li>
					) : null}
					
					{orderItem.glass ? (
						<li key="6" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 6`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{config.i18nStrings[orderItem.glass][languageStream.value.current]}
							</span>
						</li>
					) : null}
					
					{orderItem.material ? (
						<li key="7" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 7`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{config.i18nStrings[orderItem.material][languageStream.value.current]}
							</span>
						</li>
					) : null}
					
					{orderItem.glass ? (
						<li key="8" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyHighlightedLabel}>
								{i18n.translate`OrderStep - order item detail - property label 8`}
							</span>
							<span className={styles.orderItemDetailPropertyHighlightedValue}>{orderItem.count}</span>
						</li>
					) : null}
					
					{orderItem.glass ? (
						<li key="9" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyLabel}>
								{i18n.translate`OrderStep - order item detail - property label 9`}
							</span>
							<span className={styles.orderItemDetailPropertyValue}>
								{i18n.translate`OrderStep - order item detail - property value - ${getConvertedPrice(orderItem.totalPrice,)}:c`}
							</span>
						</li>
					) : null}
					
					{orderItem.glass ? (
						<li key="10" className={styles.orderItemDetailProperty}>
							<span className={styles.orderItemDetailPropertyPriceLabel}>
								{i18n.translate`OrderStep - order item detail - property label 10`}
							</span>
							<span className={styles.orderItemDetailPropertyPriceValue}>
								{i18n.translate`OrderStep - order item detail - property value - ${getConvertedPrice(orderItem.totalPrice * VAT_MODIFIER[languageStream.value.current],)}:c`}
							</span>
						</li>
					) : null}
				</ul>
				
				<div className={styles.orderItemDetailVisualization}>
					<WindowVisualization orderItem={orderItem} />
				</div>
			</div>
		);
	}
	
	handleDeleteOrderItem = () => {
		if (!this.props) {
			return;
		}
		
		destroyOrderItemActionStream.push(this.props.index);
	};
	
	handleShowOrderItem = () => {
		if (!this.props) {
			return;
		}
		
		selectOrderItemActionStream.push(this.props.index);
	};
	
	handleAddOrderItem = () => {
		if (!this.props) {
			return;
		}
		
		increaseOrderItemCountActionStream.push(this.props.index);
	};
	
	handleRemoveOrderItem = () => {
		if (!this.props) {
			return;
		}
		
		decreaseOrderItemCountActionStream.push(this.props.index);
	};
}

export interface OrderStepProps {
	isOpen: boolean;
}

export interface OrderStepState {
	width: number;
	height: number;
	areDimensionsSaved: boolean;
}

export default class OrderStep extends React.Component<OrderStepProps, OrderStepState> {
	onLanguageStream: Stream<{}>;
	onOrderStream: Stream<{}>;
	
	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// console.log('OrderStep 1');
			// requestAnimationFrame(() => {
			//   console.log('OrderStep 2');
			this.forceUpdate();
			// });
		});
		this.onOrderStream = orderStream.on(() => {
			// requestAnimationFrame(() => this.forceUpdate());
			this.forceUpdate();
		});
	}
	
	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
		this.onOrderStream.end.push(true);
	}
	
	render() {
		let order = orderStream.value;
		
		return (
			<section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
				<div className={styles.wrapper}>
					<h1 className={styles.heading}>{i18n.translate`OrderStep - heading 1`}</h1>
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
					</span>
					
					<div>
						{order.items.length ? (
							<div className={styles.orderItemsList}>
								{order.items.map((orderItem, index) => (
									<OrderItemDetail key={index} orderItem={orderItem} index={index} />
								))}
							</div>
						) : null}
						
						<div className={styles.input}>
							<label htmlFor="phone">{i18n.translate`OrderStep - phone`}</label>
							<Input type="text" name="phone" handleChange={this.handlePhoneChange} value={order.phone} />
						</div>
						
						<ButtonGroup align="center">
							<Button
								type="flat"
								size="large"
								label={i18n.translate`OrderStep - button 1`}
								handleClick={this.handleCloseClick}
							/>
							<Button
								type="flat"
								size="large"
								label={i18n.translate`OrderStep - button 2`}
								handleClick={this.handleFinishClick}
							/>
						</ButtonGroup>
					</div>
				</div>
			</section>
		);
	}
	
	handleCloseClick = () => {
		openStepActionStream.push(null);
	};
	
	handlePhoneChange = (value) => {
		if (value || value === '') {
			updatePhoneActionStream.push(value);
		}
	};
	
	handleFinishClick = () => {
		let order = _.cloneDeep(orderStream.value);
		let items: Array<any> = [];
		
		for (let i = 0; i < order.items.length; i++) {
			if (order.items[i].price > 0) {
				items.push(order.items[i]);
			}
			
			// add some translations
			if (order.items[i].control === 'RIGHT') {
				order.items[i].controlTranslation = config.i18nStrings.RIGHT;
			}
			if (order.items[i].control === 'LEFT') {
				order.items[i].controlTranslation = config.i18nStrings.LEFT;
			}
			if (order.items[i].glass === 'DOUBLE') {
				order.items[i].glassTranslation = config.i18nStrings.DOUBLE;
			}
			if (order.items[i].glass === 'TRIPLE') {
				order.items[i].glassTranslation = config.i18nStrings.TRIPLE;
			}
			if (order.items[i].material === 'PLASTIC') {
				order.items[i].materialTranslation = config.i18nStrings.PLASTIC;
			}
			if (order.items[i].material === 'WOOD') {
				order.items[i].materialTranslation = config.i18nStrings.WOOD;
			}
		}
		
		if (items.length) {
			order.items = items;
			order.uuid = orderStream.value.uuid = uuid4();
			order.phone = orderStream.value.phone = orderStream.value.phone || '';
			
			let serializedOrder = Object.assign({}, order);
			let openedWindow = window.open(link('importOrder.html'), '_blank');
			
			openedWindow.focus();
			openedWindow.addEventListener(
				'load',
				() => {(openedWindow as any).importOrder(serializedOrder);},
				true,
			);
		}
	};
	
	handleDeleteOrderItem = (index) => {
		destroyOrderItemActionStream.push(index);
	};
	
	handleShowOrderItem = (index) => {
		selectOrderItemActionStream.push(index);
	};
	
	handleAddOrderItem = (index) => {
		increaseOrderItemCountActionStream.push(index);
	};
	
	handleRemoveOrderItem = (index) => {
		decreaseOrderItemCountActionStream.push(index);
	};
}
