import colors from './colors';
import typographicScale from './typographicScale';
import grid from './grid';

let text: any = {
	color: colors.text,
	backgroundColor: colors.background,
	fontSize: typographicScale.base,
	lineHeight: grid.baselineHeight / typographicScale.base,
	fontWeight: 'normal',
	// fontFeatures: `'kern', 'liga', 'clig', 'calt', 'onum', 'pnum'`,
	fontFamily: `Arial, sans-serif`,

	selection: {
		color: colors.background,
		backgroundColor: colors.secondary2.base,
	},
};

export default text;
