import config from '../config';

const ROUTE_CLEANER_REGEX: RegExp = /^\s*[#\/]+|[#\/]*\s*$/g;

let urlAppRoot: string = config.urlAppRoot.replace(ROUTE_CLEANER_REGEX, '');

if (urlAppRoot.length) {
  urlAppRoot = `/${urlAppRoot}`;
}

export default urlAppRoot;
