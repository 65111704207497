let typographicScale: any = {
  1: 10,
  2: 12,
  3: 14,
  4: 16,
  5: 18,
  6: 20,
  7: 22,
  8: 24,
  9: 28,
  10: 32,
  11: 36,
  12: 40,
  13: 48,
  14: 60,
  15: 72,
  16: 84,
  17: 96,
  18: 108,
  19: 120,
  20: 132,
};

typographicScale.base = typographicScale[4];

export default typographicScale;
