import _ from 'lodash';
import React, {useState, useCallback, useRef} from 'react';

import {Window} from './Window';
import styles from './WindowVisualization.css';
import dataStream from '../streams/dataStream';
import I18n from '../libs/I18n';

let i18n = new I18n();

export default function WindowVisualization({orderItem}) {
	let [sizeInteriorView, setSizeInteriorView] = useState({
		bottom: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		width: 0,
		x: 0,
		y: 0,
	});
	
	let interiorViewElement = useRef(null);
	
	let handleResizeInteriorView = useCallback((frameSize) => {
		if (interiorViewElement) {
			let size = interiorViewElement.current.getBoundingClientRect();
			
			setSizeInteriorView({
				left: frameSize.left - size.left,
				top: frameSize.top - size.top,
				width: frameSize.width,
				height: frameSize.height,
			});
		}
	}, []);
	
	if (!orderItem || !orderItem) {
		return null;
	}
	
	if (!(orderItem.width >= 300 && orderItem.width <= 2500 && orderItem.height >= 300 && orderItem.height <= 2500)) {
		return (
			<div>
				<p>{i18n.translate`StepsList - max width`}</p>
				<p>{i18n.translate`StepsList - max height`}</p>
			</div>
		);
	}
	
	let ratio = orderItem.height / orderItem.width;
	
	return (
		<div className={styles.root + (ratio >= 1 ? ` ${styles.isTall}` : '')}>
			<figure ref={interiorViewElement} className={styles.window}>
				<Window
					width={orderItem.width}
					height={orderItem.height}
					lamellaColor={orderItem.lamellaColor.rgbHex}
					profileColor={orderItem.profileColor.rgbHex}
					control={orderItem.control}
					onResize={handleResizeInteriorView}
				/>
			</figure>
		</div>
	);
}
